import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(protected router: Router, protected authenticationService: AuthenticationService) { }

  canActivate(): Promise<boolean> {
    return new Promise(
      resolve => {
        if (this.authenticationService.isAuthenticated()) {
          resolve(true);
        } else {
          if (window.location.href.includes('textmatch')) {
            this.router.navigate(['/textmatch/login']);
          } else {
            this.router.navigate(['/login']);
          }
          resolve(false);
        }
      }
    );
  }

}
