import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
})
export class RedirectComponent {

  constructor() {
    window.location.href = 'https://www.nihi.org.nz/';
  }

}
