import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { CognitoUsersService } from '../cognito-users.service';
import { PasswordPattern } from '../general-utils';

@Component({
  selector: 'app-force-change-pwd',
  templateUrl: './force-change-pwd.component.html',
  styleUrls: ['./force-change-pwd.component.scss']
})
export class ForceChangePWDComponent implements OnInit {
  step = 0;
  submitPending = false;
  showErrorMessage = false;

  requiredAttributes: any = [];
  email = '';

  newPassword = '';
  confirmNewPassword = '';

  passwordVisibility = false;
  confirmPasswordVisibility = false;

  passwordPattern = PasswordPattern;

  togglePasswordVisibility(index: number) {
    if (index === 0) {
      this.passwordVisibility = !this.passwordVisibility;
    } else if (index === 1) {
      this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
    }
  }

  constructor(private location: Location, protected cognitoUsersService: CognitoUsersService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.requiredAttributes = this.route.snapshot.queryParams.requiredAttributes;
    // if (!this.requiredAttributes) {
    //   this.location.back();
    // }
  }

  activateAccount() {
    if (this.newPassword !== null) {

      this.submitPending = true;
      this.showErrorMessage = false;

      this.cognitoUsersService.completeNewPasswordChallenge(this.newPassword, this.requiredAttributes).subscribe(
        response => {
          if (response === false) {
            this.showErrorMessage = true;
            this.submitPending = false;
          } else {
            this.email = response;
            this.step = 1;
          }
        }
      );

    }
  }
}
