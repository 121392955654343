import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataRetrievalService } from 'src/app/data-retrieval.service';
import { GeneralUtils, mobileNoPattern, mobileNoPatternTwoFormat } from 'src/app/general-utils';
import { Registration } from 'src/app/model/registration.model';
import { MustMatch } from 'src/app/util/form-element/MustMatch';
import { environment } from 'src/environments/environment';
import { Message } from 'primeng//api';

@Component({
  selector: 'app-new-registration-textmatch-lakes',
  templateUrl: './new-registration-textmatch-lakes.component.html',
  styleUrls: ['./new-registration-textmatch-lakes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewRegistrationTextmatchLakesComponent implements OnInit {

  protected registration: Registration;
  regForm: FormGroup;

  protected submitted = false;

  protected mobilePattern = mobileNoPattern;

  protected type: string;
  protected categoryFamilyType: string;

  protected optionsRegion: any;
  protected optionsRotoruaSuburb: any;
  protected optionsEthnicity: any;
  protected optionsGender: any;
  protected optionsAgeGrp: any;
  protected optionsCategories: any;
  protected optionsChildGender: any;
  protected optionsChildRelationship: any;
  protected optionsConsent: any;
  protected optionsHearAbout: any;

  protected version = 'en';

  protected minDate = new Date();
  protected today = new Date();
  protected minSixDate = new Date();

  protected _projectName: string = null;
  protected _filterMobileNo: string = null;

  messages_1: Message[];
  messages_2: Message[];

  adminAccess = false;
  userUpdate = false;

  constructor(protected dataRetrievalService: DataRetrievalService
            , protected formBuilder: FormBuilder
            , protected cdRef: ChangeDetectorRef
            , protected titleService: Title) {
              this.titleService.setTitle('txtpepi');
            }

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.adminAccess = true;
    this.reset();
    this.getDropdownOptions();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set filterMobileNo(mobileNo: string) {
    this._filterMobileNo = mobileNo;
    if (this._filterMobileNo == null) {
      alert('Please select a user from user details and click edit.');
    }
  }

  ngOnInit(): void {
    this.minSixDate.setMonth(this.minSixDate.getMonth() - 6);

    this.reset();
    this.getDropdownOptions();
    this.onChange();

    if (this._filterMobileNo) {
      this.getUserRegistrationData();
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  reset() {
    this.submitted = false;
    this.registration = new Registration();
    this.regForm = this.formBuilder.group({
      registerType: new FormControl(this.registration.registerType, Validators.required),
      name: new FormControl(this.registration.name, [Validators.required, Validators.nullValidator]),
      mobile: new FormControl({value: this.registration.mobile, disabled: this.adminAccess},
          [Validators.required, Validators.pattern(mobileNoPatternTwoFormat)]),
      confirmMobile: new FormControl({value: this.registration.confirmMobile, disabled: this.adminAccess},
          [Validators.required, Validators.pattern(mobileNoPatternTwoFormat)]),
      region: new FormControl(this.registration.region, [Validators.required, Validators.nullValidator]),
      otherRegion: new FormControl(this.registration.otherRegion),
      suburb: new FormControl(this.registration.suburb), // check based on region
      ethnicity: new FormControl(this.registration.ethnicity, [Validators.required, Validators.nullValidator]),
      otherEthnicity: new FormControl(this.registration.otherEthnicity),
      gender: new FormControl(this.registration.gender, [Validators.required, Validators.nullValidator]),
      ageGrp: new FormControl(this.registration.ageGrp, [Validators.required, Validators.nullValidator]),
      category: new FormControl(this.registration.category, [Validators.required, Validators.nullValidator]),
      estimatedDueDate: new FormControl(this.registration.estimatedDueDate), // check based on category
      childDob: new FormControl(this.registration.childDob), // check based on category
      childName: new FormControl(this.registration.childName), // check based on category
      childGender: new FormControl(this.registration.childGender), // check based on category
      childRelationship: new FormControl(this.registration.childRelationship), // check based on category
      consent: new FormControl({value: this.registration.consent, disabled: this.adminAccess}), // check based on registerType
      hearAbout: new FormControl(this.registration.hearAbout),
      hearAboutOther: new FormControl(this.registration.hearAboutOther),
      referrer: new FormControl(this.registration.referrer),
      }, {
      validator: MustMatch('mobile', 'confirmMobile')
    });
  }

  // convenience getter for easy access to form fields
  get controls() { return this.regForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.regForm.valid) {
      this.registration = Object.assign(this.registration, this.regForm.getRawValue());

      this.registration.estimatedDueDate = (this.registration.estimatedDueDate ?
                          GeneralUtils.convertDateToISODateString(this.registration.estimatedDueDate)
                          : '');
      this.registration.childDob = (this.registration.childDob ?
                          GeneralUtils.convertDateToISODateString(this.registration.childDob)
                          : '');
      // console.log(this.registration.referrerDate);
      if (!this.registration.referrerDate) {
        this.registration.referrerDate = GeneralUtils.convertDateToISODateString(new Date());
      }

      this.registration.projectName = environment.projectName;
      this.registration.projectVersion = environment.projectVersion;
      this.registration.timezone = environment.timezone;
      this.registration.source = 'self';
      this.registration.userStatus = this.userUpdate ? 'Update' : 'New';
      if (!this.registration.locale) {
        this.registration.locale = this.version;
      }
      this.registration.mobile = GeneralUtils.addMobileNoCountryCode(this.registration.mobile);
      this.registration.confirmMobile = null;

      // console.log(this.registration.version);
      if (!this.registration.version) {
        this.registration.version = 'Lakes: English';
        if (this.version === 'mi') {
          this.registration.version = 'Lakes: Te Reo';
        }
      }

      this.dataRetrievalService.postUserRegistrationDetails(JSON.stringify(this.registration, (key, value) => value ? value : undefined))
        .subscribe(response => {

          if (response !== null && response.hasOwnProperty('error')) {
            // console.log(response.error);
            if (response.error.indexOf('registered already') !== -1) {
              alert('Phone number has already been registered. Please use another number or contact us at txtpepi@auckland.ac.nz');
            } else {
              alert('There is a problem in registering this mobile number. ' +
                  'Please contact the txtpepi support team at txtpepi@auckland.ac.nz.');
            }
          } else {
            // console.log('Registration submitted. You can now enter a new user registration.');
            if (this.adminAccess) {
              alert('Registration was successfully updated.');
            } else {
              if (this.version === 'en') {
                alert('Thank you for your registration. Your first text message will arrive shortly.');
              } else {
                alert('Thank you for your registration. Your first text message will arrive shortly.');
              }
              this.reset();
            }
            window.scrollTo(0, 0);
          }
        },
        error => {
          // console.log('Error in submitting');
          alert('There is a problem in registering this mobile number. Please contact the txtpepi support team. at txtpepi@auckland.ac.nz');
        }
      );

      // console.log(this.registration);
    } else {
      console.log('errors ' + this.regForm.errors);
    }
  }

  onChange() {
    this.type = this.controls.registerType.value;
    this.controls.consent.setValidators(null);
    this.controls.hearAbout.setValidators(null);

    if (this.type === 'self') {
      this.controls.consent.setValidators([Validators.required, Validators.nullValidator]);
      this.controls.hearAbout.setValidators([Validators.required, Validators.nullValidator]);
    }
    this.controls.consent.reset();
    this.controls.hearAbout.reset();

    this.regForm.updateValueAndValidity();
  }

  onCategoryChange() {
    const categoryVal = this.controls.category.value;
    this.controls.estimatedDueDate.setValidators(null);
    this.controls.childDob.setValidators(null);
    this.controls.childName.setValidators(null);
    this.controls.childGender.setValidators(null);
    this.controls.childRelationship.setValidators(null);

    switch (categoryVal) {
      case 'PregnantMother':
        this.categoryFamilyType = 'self';
        this.controls.estimatedDueDate.setValidators([Validators.required, Validators.nullValidator]);
        break;
      case 'PregnantMotherFamily':
        this.categoryFamilyType = 'other';
        this.controls.estimatedDueDate.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childRelationship.setValidators([Validators.required, Validators.nullValidator]);

        break;
      case 'MotherWithBaby':
        this.categoryFamilyType = 'self';
        this.controls.childDob.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childName.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childGender.setValidators([Validators.required, Validators.nullValidator]);

        break;
      case 'MotherWithBabyFamily':
        this.categoryFamilyType = 'other';
        this.controls.childDob.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childName.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childGender.setValidators([Validators.required, Validators.nullValidator]);
        this.controls.childRelationship.setValidators([Validators.required, Validators.nullValidator]);

        break;
      default:
        break;
    }

    this.controls.estimatedDueDate.reset();
    this.controls.childDob.reset();
    this.controls.childName.reset();
    this.controls.childGender.reset();
    this.controls.childRelationship.reset();

    this.regForm.updateValueAndValidity();
  }

  onRegionChange() {
    const regionVal = this.controls.region.value;

    this.controls.suburb.setValidators(null);
    this.controls.otherRegion.setValidators(null);

    if (regionVal === 'rotorua') {
      this.controls.suburb.setValidators([Validators.required, Validators.nullValidator]);
    } else if (regionVal === 'other') {
      this.controls.otherRegion.setValidators([Validators.required, Validators.nullValidator]);
    }

    this.controls.otherRegion.reset();
    this.controls.suburb.reset();

    this.regForm.updateValueAndValidity();
  }

  onHearAboutChange() {
    const hearAbtVal = this.controls.hearAbout.value;

    this.controls.hearAboutOther.setValidators(null);

    if (hearAbtVal.includes('other')) {
      this.controls.hearAboutOther.setValidators([Validators.required, Validators.nullValidator]);
    } else {
      this.controls.hearAboutOther.reset();
    }

    this.regForm.updateValueAndValidity();
  }

  onEthnicityChange() {
    const ethnicityVal = this.controls.ethnicity.value;

    this.controls.otherEthnicity.setValidators(null);

    if (ethnicityVal.includes('other')) {
      this.controls.otherEthnicity.setValidators([Validators.required, Validators.nullValidator]);
    } else {
      this.controls.otherEthnicity.reset();
    }

    this.regForm.updateValueAndValidity();
  }

  getUserRegistrationData() {
    this.dataRetrievalService.getUserDetailsWithMobile(this.projectName, encodeURI(this._filterMobileNo))
    .subscribe(response => {

      this.messageReset();

      if (response !== null && response.hasOwnProperty('error')) {
        this.messages_2.push({ severity: 'info', summary: 'Error:', detail: response.error });
      } else {
        this.reset();

        this.registration = Object.assign({}, response.attributes);
        this.registration.confirmMobile = this.registration.mobile;
        if (this.registration.estimatedDueDate) {
          this.registration.estimatedDueDate = GeneralUtils.convertISODateStringToDate(this.registration.estimatedDueDate);
        }
        if (this.registration.childDob) {
          this.registration.childDob = GeneralUtils.convertISODateStringToDate(this.registration.childDob);
        }

        this.regForm.patchValue(this.registration);
        this.userUpdate = true;

        console.log(this.regForm.value);
        this.regForm.updateValueAndValidity();
        window.scrollTo(0, 0);
        this.cdRef.detectChanges();
      }
    },

    error => {
      this.messageReset();
      this.regForm.updateValueAndValidity();
      this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'User Registration retreival error.' });

      // this.disableSubmit = false;
    }
  );
  }

  private messageReset() {
    this.messages_1 = [];
    this.messages_2 = [];
  }

  protected numberInputValidator(event: any) {
    GeneralUtils.numberInputValidator(event);
  }

  protected getDropdownOptions() {
    this.optionsRegion = [
      { label: 'Select area', value: null },
      { label: 'Rotorua', value: 'rotorua' },
      { label: 'Taupō', value: 'taupo' },
      { label: 'Turangi', value: 'turangi' },
      { label: 'Mangakino', value: 'mangakino' },
      { label: 'Tairāwhiti District (e.g. Gisborne)', value: 'tairawhiti' },
      { label: 'Other', value: 'other' },
    ];

    this.optionsRotoruaSuburb = [
      { label: 'Select suburb', value: null },
      { label: 'Fairy Springs', value: 'fairySprings'},
      { label: 'Fenton Park', value: 'fentonPark'},
      { label: 'Fordlands', value: 'fordlands'},
      { label: 'Glenholme', value: 'glenholme'},
      { label: 'Hannahs Bay', value: 'hannahsBay'},
      { label: 'Hillcrest', value: 'hillcrest'},
      { label: 'Holdens Bay', value: 'holdensBay'},
      { label: 'Kawaha Point', value: 'kawahaPoint'},
      { label: 'Koutu', value: 'koutu'},
      { label: 'Lynmore', value: 'lynmore'},
      { label: 'Ngapuna', value: 'ngapuna'},
      { label: 'Ngongotaha', value: 'ngongotaha'},
      { label: 'Ohinemutu', value: 'ohinemutu'},
      { label: 'Owhata', value: 'owhata'},
      { label: 'Pukehangi', value: 'pukehangi'},
      { label: 'Selwyn Heights', value: 'selwynHeights'},
      { label: 'Springfield', value: 'springfield'},
      { label: 'Sunnybrook', value: 'sunnybrook'},
      { label: 'Tihiotonga', value: 'tihiotonga'},
      { label: 'Utuhina', value: 'utuhina'},
      { label: 'Victoria', value: 'victoria'},
      { label: 'Western Heights', value: 'westernHeights'},
      { label: 'Whakarewarewa', value: 'whakarewarewa'}
    ];

    this.optionsEthnicity = [
      { label: 'New Zealand European', value: 'nzEuropean' },
      { label: 'Māori', value: 'maori' },
      { label: 'Samoan', value: 'samoan' },
      { label: 'Cook Island Maori', value: 'cookIsland' },
      { label: 'Tongan', value: 'tongan' },
      { label: 'Niuean', value: 'niuean' },
      { label: 'Chinese', value: 'chinese' },
      { label: 'Indian', value: 'indian' },
      { label: 'Other such as Dutch, Japanese, Tokelauan', value: 'other' }
    ];

    this.optionsGender = [
      { label: 'Select gender', value: null },
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Another gender', value: 'anotherGender' }
    ];

    this.optionsAgeGrp = [
      { label: 'Select age group', value: null },
      { label: '16 years and under', value: 'under16' },
      { label: '17-24 years', value: '17-24' },
      { label: '25-34 years', value: '25-34' },
      { label: '35-44 years', value: '35-44' },
      { label: '45-54 years', value: '45-54' },
      { label: '55-64 years', value: '55-64' },
      { label: '65 years and over', value: 'over65' }
    ];

    this.optionsCategories = [
      { label: 'Select category...', value: null },
      { label: 'A pregnant mother', value: 'PregnantMother' },
      { label: 'A family/whānau member of the pregnant mother', value: 'PregnantMotherFamily' },
      { label: 'A mother with a baby under 6 months of age', value: 'MotherWithBaby' },
      { label: 'A family/whānau member of a baby under 6 months of age', value: 'MotherWithBabyFamily'},
    ];

    this.optionsChildGender = [
      { label: 'Select baby gender... (no differences between "Boy" and "Male")', value: null },
      { label: 'Boy', value: 'boy' },
      { label: 'Girl', value: 'girl' },
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' }
    ];

    this.optionsChildRelationship = [
        { label: 'Select relationship to child...', value: null },
        { label: 'Father', value: 'father' },
        { label: 'Mother', value: 'mother' },
        { label: 'Grandfather', value: 'grandfather' },
        { label: 'Grandmother', value: 'grandmother' },
        { label: 'Aunty', value: 'aunty' },
        { label: 'Uncle', value: 'uncle' },
        { label: 'Other whānau', value: 'other' }
    ];

    this.optionsConsent = [
       { label: 'Select yes/no...', value: null },
       { label: 'Yes', value: 'Y' },
       { label: 'No', value: 'N' }
    ];

    this.optionsHearAbout = [
       { label: 'A doctor/ nurse/ midwife', value: 'fromHealthProfessional' },
       { label: 'Poster in a clinic', value: 'fromPoster' },
       { label: 'Whānau', value: 'whanau' },
       { label: 'Other', value: 'other' }
    ];
  }
}
