import {Component, Renderer2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, private renderer: Renderer2) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const link = this.renderer.selectRootElement('link[rel="icon"]');
        if (event.url.includes('textmatch')) {
          this.renderer.setAttribute(link, 'href', '/assets/img/textmatch-logo.png');
        } else {
          this.renderer.setAttribute(link, 'href', 'favicon.ico');
        }
      }
    });
  }
}
