import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataRetrievalService {

  projectVersion = '1.0.0';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  constructor(protected httpClient: HttpClient) { }

  getUserDetails(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/recipient?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion);
  }

  getUserDetailsWithMobile(projectName: string, mobile: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/recipient?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + '&mobile=' + mobile + '&recipientExistenceCheck=Y');
  }

  getUserDetailsWithStatus(projectName: string, status: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/recipient?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + '&status=' + status);
  }

  getUserDetailsWithOtherFieldsInSubscriptionDBTable(projectName: string, fields: string[]): Observable<any> {
    const fieldsString: string = fields.length ? '&fromSubscription=' + fields.join(',') : '';
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/recipient?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + fieldsString);
  }

  getCandidateUserDetails(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/recipient?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + '&recipientCandidate=Y');
  }

  getUserRegistrationDetails(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/subscription?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion);
  }

  getModule(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/module?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion);
  }

  getOutgoingMessageLog(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/scheduleddelivery?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion, {responseType: 'text'});
  }

  getOutgoingMessageLogDateRangeMobile(projectName: string, fromDate: string, toDate: string, toNumber: string): Observable<any> {
    if  (fromDate && toDate)  {
      return this.httpClient.get(environment.APIBaseURL + '/mp/admin/scheduleddelivery?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + '&fromDate=' + fromDate + '&toDate=' + toDate, {responseType: 'text'});

    } else if (toNumber)  {
      return this.httpClient.get(environment.APIBaseURL + '/mp/admin/scheduleddelivery?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion + '&toNumber=' + toNumber, {responseType: 'text'});
    } else {
      return this.getOutgoingMessageLog(projectName);
    }
  }

  getIncomingMessageLog(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/receivedmessage?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion);
  }

  getMobileMapping(projectName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/mobilemapping?projectName=' + projectName +
      '&projectVersion=' + this.projectVersion);
  }

  getRolePermission(userName: string): Observable<any> {
    return this.httpClient.get(environment.APIBaseURL + '/mp/admin/rolepermission?userName=' + userName);
  }

  putUserRegistrationData(data: any): Observable<any> {
    return this.httpClient.put(environment.APIBaseURL + '/mp/admin/subscription', data, this.httpOptions);
  }

  putMobileMapping(mobileMapping: any): Observable<any> {
    return this.httpClient.put(environment.APIBaseURL + '/mp/admin/mobilemapping', mobileMapping, this.httpOptions);
  }

  postUserRegistrationDetails(registration: any): Observable<any> {
    return this.httpClient.post<any>(environment.APIBaseURL + '/mp/admin/recipient', registration, this.httpOptions);
  }

  postCandidateUserRegistrationDetails(registration: string): Observable<any> {
    return this.httpClient.post<any>(environment.APIBaseURL + '/mp/admin/recipient?recipientCandidate=Y', registration, this.httpOptions);
  }

  putSubscriptionSwitchProgram(switchData: any): Observable<any> {
    return this.httpClient.put<any>(environment.APIBaseURL + '/mp/admin/subscriptionSwitch', switchData, this.httpOptions);
  }

  postArchiveUser(postData: any): Observable<any> {
    return this.httpClient.post<any>(environment.APIBaseURL + '/mp/admin/archiveuser', postData, this.httpOptions);
  }

  postAdhocMessage(adhocMessage: any): Observable<any> {
    return this.httpClient.post<any>(environment.APIBaseURL + '/mp/admin/scheduleddelivery', adhocMessage, this.httpOptions);
  }

  postUpdatedMessage(updatedMessage: any): Observable<any> {
    return this.httpClient.post<any>(environment.APIBaseURL + '/mp/admin/receivedmessage', updatedMessage, this.httpOptions);
  }

  getRegistrationDropdownOptions(): Observable<object> {
    const options = {
      preferredMessageDeliveryTime: [
        { label: 'Select time...', value: null },
        { label: 'Morning (8am-12pm)', value: 'morning' },
        { label: 'Afternoon (12-5pm)', value: 'afternoon' },
        { label: 'Evening (5-8pm)', value: 'evening' },
        { label: 'Day (9am-5pm)', value: 'day' },
      ],
      culture: [
        { label: 'Select culture...', value: null },
        { label: 'Māori', value: 'Maori' },
        { label: 'Pacific: Samoan', value: 'Pacific_Samoan' },
        { label: 'Pacific: Tongan', value: 'Pacific_Tongan' },
        { label: 'Pacific: Other pacific islander', value: 'Pacific_Other' },
        { label: 'Non-Maori/Pacific', value: 'Non_Maori' },
      ],
      motivations: [
        { label: 'Select motivation...', value: null },
        { label: 'family', value: 'family' },
        { label: 'whanau', value: 'whanau' },
        { label: 'wife', value: 'wife' },
        { label: 'husband', value: 'husband' },
        { label: 'partner', value: 'partner' },
        { label: 'your children', value: 'your children' },
        { label: 'your grandchildren', value: 'your grandchildren' },
        { label: 'your mokopuna', value: 'your mokopuna' },
        { label: 'your health', value: 'your health' },
        { label: 'your friends', value: 'your friends' },
      ],
      exercisePrescription: [
        { label: 'Select prescription...', value: null },
        { label: 'Level 1', value: 'Level_1' },
        { label: 'Level 2', value: 'Level_2' },
        { label: 'Level 3', value: 'Level_3' },
      ],
      secretionLoadModule: [
        { label: 'Select yes/no...', value: null },
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
      smokingModule: [
        { label: 'Select yes/no...', value: null },
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
      version: [
        { label: 'Select version...', value: null },
        { label: 'English', value: 'English' },
        { label: 'Cook Island Maori', value: 'Cook_Island_Maori' },
      ],
      gender: [
        { label: 'Select gender...', value: null },
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
      ],
      ethnicity: [
        { label: 'Select ethnicity...', value: null },
		    { label: 'Maori', value: 'Maori' },
        { label: 'Chinese', value: 'Chinese' },
        { label: 'Korean', value: 'Korean' },
        { label: 'Japanese', value: 'Japanese' },
        { label: 'Other Asian', value: 'Other Asian' },
        { label: 'Samoan', value: 'Samoan' },
        { label: 'Tongan', value: 'Tongan' },
        { label: 'Cook Island Maori', value: 'Cook Island Maori' },
        { label: 'Niuean', value: 'Niuean' },
        { label: 'Tuvaluan', value: 'Tuvaluan' },
        { label: 'Tokelauan', value: 'Tokelauan' },
        { label: 'Fijian', value: 'Fijian' },
        { label: 'Other Pacific Islander', value: 'Other Pacific Islander' },
        { label: 'Indian', value: 'Indian' },
        { label: 'Fijian Indian', value: 'Fijian Indian' },
        { label: 'Bangladeshi', value: 'Bangladeshi' },
        { label: 'Pakistani', value: 'Pakistani' },
        { label: 'Sri Lankan', value: 'Sri Lankan' },
        { label: 'South African Indian', value: 'South African Indian' },
        { label: 'Nepalese', value: 'Nepalese' },
        { label: 'Other South Asian', value: 'Other South Asian' },
		    { label: 'NZ European', value: 'NZ European' },
		    { label: 'Not specified', value: 'Not specified' },
        { label: 'Other ethnicity', value: 'Other' },
      ],

      maoriEthnicity: [
        { label: 'Select ethnicity...', value: null },
        { label: 'Maori', value: 'Maori' },
        { label: 'NZ European', value: 'NZ European' },
      ],
      pacificEthnicity: [
        { label: 'Select ethnicity...', value: null },
        { label: 'Samoan', value: 'Samoan' },
        { label: 'Tongan', value: 'Tongan' },
        { label: 'Cook Island Maori', value: 'Cook Island Maori' },
        { label: 'Niuean', value: 'Niuean' },
        { label: 'Tuvaluan', value: 'Tuvaluan' },
        { label: 'Tokelauan', value: 'Tokelauan' },
        { label: 'Fijian', value: 'Fijian' },
        { label: 'Other Pacific Islander', value: 'Other Pacific Islander' },
      ],
      asianEthnicity: [
        { label: 'Select ethnicity...', value: null },
        { label: 'Chinese', value: 'Chinese' },
        { label: 'Korean', value: 'Korean' },
        { label: 'Japanese', value: 'Japanese' },
        { label: 'Other Asian', value: 'Other Asian' },
      ],
      southAsianEthnicity: [
        { label: 'Select ethnicity...', value: null },
        { label: 'Indian', value: 'Indian' },
        { label: 'Fijian Indian', value: 'Fijian Indian' },
        { label: 'Bangladeshi', value: 'Bangladeshi' },
        { label: 'Pakistani', value: 'Pakistani' },
        { label: 'Sri Lankan', value: 'Sri Lankan' },
        { label: 'South African Indian', value: 'South African Indian' },
        { label: 'Nepalese', value: 'Nepalese' },
        { label: 'Other South Asian', value: 'Other South Asian' },
      ],
      otherEthnicity: [
        { label: 'Select ethnicity...', value: null },
        { label: 'Other', value: 'Other' },
      ],
      consent: [
        { label: 'Select yes/no...', value: null },
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
    };

    return of(options);
  }
}
