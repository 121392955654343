import { Component, OnInit, Input } from '@angular/core';
import { Message } from 'primeng//api';
import { mobileNoPattern, GeneralUtils } from '../../general-utils';
import { DataRetrievalService } from '../../data-retrieval.service';

@Component({
  selector: 'app-new-registration-stomp',
  templateUrl: './new-registration-stomp.component.html',
  styleUrls: ['./new-registration-stomp.component.scss']
})
export class NewRegistrationStompComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.clearFormInputs();
    this.getRegistrationDropdownOptions();
  }
  get projectName(): string {
    return this._projectName;
  }

  protected _projectName: string = null;

  disableSubmit = false;

  messages_1: Message[] = [];
  messages_2: Message[] = [];

  mobilePattern = mobileNoPattern;

  dropdownOptions: any = {
    version: [],
  };

  enteredFormInput: any = {
    programStartDate: null,
    preferredName: null,
    preferredMobileNumber: null,
    confirmMobileNumber: null,
  };

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  clearFormInputs() {

    this.enteredFormInput = {
      programStartDate: null,
      preferredName: null,
      preferredMobileNumber: null,
      confirmMobileNumber: null,
    };

  }

  getRegistrationDropdownOptions() {

    this.dataRetrievalService.getRegistrationDropdownOptions().subscribe(
      dropdownOptions => {
        this.dropdownOptions = dropdownOptions;
      }
    );

  }

  submitRegistration() {

    this.disableSubmit = true;
    this.messages_1 = [];
    this.messages_2 = [];
    this.messages_2.push({ severity: 'info', summary: 'Sending:', detail: 'Submitting registration, please wait...' });

    const registration = {
      projectName: this.projectName,
      projectVersion: '1.0.0',
      timezone: 'Pacific/Rarotonga',
      programStartDate: GeneralUtils.convertDateToISODateString(this.enteredFormInput.programStartDate),
      mobile: this.enteredFormInput.preferredMobileNumber,
      name: this.enteredFormInput.preferredName,
      version: this.enteredFormInput.version,
    };

    this.dataRetrievalService.postUserRegistrationDetails(registration).subscribe(
      response => {

        this.messages_1 = [];
        this.messages_2 = [];

        if (response !== null && response.hasOwnProperty('error')) {
          this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Registration not submitted. ' + response.error });
        } else {
          this.messages_1.push({ severity: 'info', summary: 'Sent:',
            detail: 'Registration submitted. You can now enter a new user registration.' });
          this.clearFormInputs();
          window.scrollTo(0, 0);
        }

        this.disableSubmit = false;

      },

      error => {
        this.messages_1 = [];
        this.messages_2 = [];

        this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Registration not submitted. Unknown error.' });

        this.disableSubmit = false;
      }
    );

  }

  numberInputValidator(event: any) {
    GeneralUtils.numberInputValidator(event);
  }

}
