import { IAuthenticationDetailsData, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AttributeListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

import { environment } from '../environments/environment';

export class CognitoUtils {

    public static getAuthDetails(email: string, password: string): IAuthenticationDetailsData {
        return {
            Username: email,
            Password: password,
        };
    }

    public static getUserPool() {
        return new CognitoUserPool(environment.cognitoSettings);
    }

    public static getAttribute(attrs: CognitoUserAttribute[], name: string): CognitoUserAttribute {
        return attrs.find(atr => atr.getName() === name);
    }

    public static getAttributeValue(attrs: AttributeListType, name: string, defValue: any): string {
        const attr = attrs.find(atr => atr.Name === name);
        return attr ? attr.Value : defValue;
    }

    public static getActiveAttribute(attrs: AttributeListType): boolean {
        return CognitoUtils.getAttributeValue(attrs, 'custom:active', '1') === '1';
    }

    public static createNewUserAttributes(): CognitoUserAttribute[] {
        const emailAttribute = new CognitoUserAttribute({ Name: 'email', Value: '' });
        return [
            emailAttribute
        ];
    }

    public static createNewUserGroupAttributes(email: string, fullName: string, group: string): CognitoUserAttribute[] {
        const emailAttribute = new CognitoUserAttribute({ Name: 'email', Value: email });
        const fullNameAttribute = new CognitoUserAttribute({ Name: 'name', Value: fullName });
        const groupAttribute = new CognitoUserAttribute({ Name: 'address', Value: group });

        return [emailAttribute, fullNameAttribute, groupAttribute];
    }

    public static createUpdatableUserAttributesData(request): AttributeListType {
        const preferedUsername = { Name: 'preferred_username', Value: request.username };
        const emailAttribute = { Name: 'email', Value: request.email };
        const emailVerifiedAttribute = { Name: 'email_verified', Value: 'true' };
        const activeAttribute = { Name: 'custom:active', Value: (request.active ? 1 : 0).toString() };
        return [
            preferedUsername, emailAttribute, emailVerifiedAttribute,
            activeAttribute
        ];
    }


}
