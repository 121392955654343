import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng//api';
import { CognitoUsersService } from '../cognito-users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  disableSubmit = false;

  messages: Message[] = [];

  enteredFormInput: any = {
    username: null,
    password: null
  };

  constructor(protected router: Router, protected cognitoUsersService: CognitoUsersService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.enteredFormInput.username = this.route.snapshot.queryParams.email;
  }

  login() {
    if (this.enteredFormInput.username !== null && this.enteredFormInput.password !== null) {

      this.disableSubmit = true;
      this.messages = [];
      this.messages.push({ severity: 'warn', summary: 'Logging:', detail: 'Logging in, please wait...' });

      this.cognitoUsersService.login(this.enteredFormInput.username, this.enteredFormInput.password).subscribe(
        response => {
          if (response === false) {
            this.messages = [];
            this.messages.push({ severity: 'warn', summary: 'Login error:',
              detail: 'Please check you have entered the correct username and password.' });
            this.disableSubmit = false;
          }
        }
      );

    }
  }
}
