import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CognitoUserSession, CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import { CognitoUtils } from './cognito-utils';

@Injectable({
  providedIn: 'root'
})
export class CognitoUsersService {

  session: CognitoUserSession;
  userPool: CognitoUserPool;
  cognitoUser: CognitoUser;

  constructor(protected httpClient: HttpClient, protected router: Router) {
    this.userPool = CognitoUtils.getUserPool();
  }

  public completeNewPasswordChallenge(password: string,
                                      requiredAttributes: any = []): Observable<any> {
    return Observable.create(
      obs => {
        if (!this.cognitoUser) {
          obs.next(false);
          return;
        }
        this.cognitoUser.completeNewPasswordChallenge(password, requiredAttributes, {
            onSuccess: result => {
              console.log(result);
              obs.next(this.cognitoUser.getUsername());
            },
            onFailure: err => {
              console.error(err);
              obs.next(false);
            }
          }
        );
      }
    );
  }

  public login_success(result: CognitoUserSession) {
    this.session = result;
    const token = result.getIdToken();
    const accessToken = result.getAccessToken();
    sessionStorage.setItem('token', JSON.stringify(token));
    sessionStorage.setItem('accessToken', JSON.stringify(accessToken));

    this.router.navigate(['/main-view']);
  }

  public login(login: string, password: string): Observable<any> {
    this.cognitoUser = new CognitoUser(this.getUserData(login));
    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const authenticationDetails = new AuthenticationDetails(CognitoUtils.getAuthDetails(login, password));
    return Observable.create(
      obs => {
        this.cognitoUser.authenticateUser(
          authenticationDetails, {
            onSuccess: result => {
              this.login_success(result);
            },
            onFailure: err => {
              console.error(err);
              obs.next(false);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
              this.router.navigate(['/force-change-password'], {
                skipLocationChange: true,
                queryParams: {requiredAttributes}
              });
            },
          }
        );
      }
    );
  }

  protected getUserData(email: string) {
    return {
      Username: email,
      Pool: this.userPool
    };
  }

  public register(emailAddress: string, password: string): void {
    this.userPool.signUp(emailAddress, password, CognitoUtils.createNewUserAttributes(), null,
      (err, result) => {
        if (err) {
          alert(err.message);
        } else {
          confirm('Please contact your system administrator to confirm you account first. ' +
            'We cannot simply log you in for security reasons. Sorry for the inconvenience.');
          // this.router.navigate(['/login'], {queryParams: {email: emailAddress}});
          // this.router.navigate(['/confirmRegister'], {queryParams: {email: emailAddress}});
        }
      });
  }

  public register_textmatch(email: string, password: string, fullName: string, organisation: string, callbackFunc: any): void {
    this.userPool.signUp(email, password,
      CognitoUtils.createNewUserGroupAttributes(email, fullName, organisation), null, callbackFunc);
  }

  public confirmRegistration(username: string, confirmationCode: string): void {
    const cognitoUser = new CognitoUser(this.getUserData(username));

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        alert(err.message);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  resendCode(username: string): void {
    const cognitoUser = new CognitoUser(this.getUserData(username));

    cognitoUser.resendConfirmationCode( (err, result) => {
      if (err) {
        alert(err.message);
      } else {
        console.log(result);
      }
    });
  }

  public resetPassword(email: string, callbacks: any): void {
    const cognitoUser = new CognitoUser(this.getUserData(email));
    cognitoUser.forgotPassword(callbacks);
  }

  public confirmPassword(email: string, verificationCode: string, newPassword: string, callbacks: any): void {
    const cognitoUser = new CognitoUser(this.getUserData(email));
    cognitoUser.confirmPassword(verificationCode, newPassword, callbacks);
  }
}
