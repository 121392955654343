import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataRetrievalService} from '../../data-retrieval.service';
import {GeneralUtils} from '../../general-utils';
import {Message} from 'primeng//api';
import {Observable} from "rxjs";

@Component({
  selector: 'app-candidate-user-table-textmatch',
  templateUrl: './candidate-user-table-textmatch.component.html',
  styleUrls: ['./candidate-user-table-textmatch.component.scss']
})
export class CandidateUserTableTextmatchComponent implements OnInit {
  GeneralUtils = GeneralUtils;
  disableSubmit = false;

  loading = true;
  maxRows = 20;
  candidateDetails: any[] = [];
  tableColumns: any[] = [];
  messages: Message[] = [];

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  protected _projectName: string = null;

  @Output() goToArchiveUserTabEvent = new EventEmitter<string>();
  @Output() goToNewUserDataTabEvent = new EventEmitter<any>();

  goToArchiveUserTab(mobile: string) {
    this.goToArchiveUserTabEvent.emit(mobile);
  }

  confirmThenHideCandidate(row: any) {
    // 1. confirm with user whether he wants to hide this row
    const userConfirmed = confirm('Are you sure you want to hide this row?');

    if (userConfirmed) {
      // 2. set hidden to 'Y'
      this.hideCandidate(row).subscribe(() => {
        this.refreshTable();
      });
    }
  }

  // update candidate to hide it in the future, then go to New User Tab
  goToNewUserDataTab(row: any) {
    // 1. update candidate, to add a hidden label, so won't show again in front-end
    this.hideCandidate(row).subscribe(()=> {
      // 2. success, go to New User tab
      row.re_referral = 'Y';
      row.recipientCandidate = undefined;
      this.goToNewUserDataTabEvent.emit(row);
    });
  }

  hideCandidate(candidate: any): Observable<any> {
    // convert data string format back
    candidate.childDob = this.isDateFormat(candidate.childDob) ? GeneralUtils.convertFormattedDateStringToISODateString(candidate.childDob) : candidate.childDob;
    candidate.estimatedDueDate = this.isDateFormat(candidate.estimatedDueDate) ?
      GeneralUtils.convertFormattedDateStringToISODateString(candidate.estimatedDueDate) : candidate.estimatedDueDate;
    candidate.referrerDate = this.isDateFormat(candidate.referrerDate) ?
      GeneralUtils.convertFormattedDateStringToISODateString(candidate.referrerDate) : candidate.referrerDate;

    const registration = JSON.parse(JSON.stringify(candidate));
    registration.notExist = undefined;
    registration.recipientCandidate = 'Y';
    registration.userStatus = 'Update';
    registration.hidden = 'Y';  // to hide in the future

    this.messages = [];
    this.disableSubmit = true;

    return new Observable(observer => {
      this.dataRetrievalService.postCandidateUserRegistrationDetails(JSON.stringify(registration, (key, value) => value ? value : undefined))
        .subscribe(response => {
            this.disableSubmit = false;
            if (response !== null && response.hasOwnProperty('error')) {
              this.messages.push({ severity: 'warn', summary: 'Tech Error:', detail: 'Candidate cannot be updated. ' + response.error });
              observer.error(response.error);
            } else {
              observer.next(candidate);
              observer.complete();
            }
          },
          error => {
            this.disableSubmit = false;
            this.messages.push({ severity: 'warn', summary: 'Tech Error:', detail: 'Candidate cannot be updated. ' + error });
            observer.error(error);
          }
        );
    });
  }

  isDateFormat(str: string): boolean {
    const regex = /^\d{2}\/\d{2}\/\d{2}$/;
    return regex.test(str);
  }

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {
    this.loading = true;
    this.getCandidateDetails();

    this.tableColumns = [
      { field: 'mobile', header: 'Mobile' },
      { field: 'name', header: 'First Name' },
      { field: 'category', header: 'Category' },
      { field: 'childDob', header: 'Child DOB' },
      { field: 'childName', header: 'child Name' },
      { field: 'childGender', header: 'child Gender' },
      { field: 'estimatedDueDate', header: 'Due Date' },
      { field: 'version', header: 'Version' },
      { field: 'culturalGroup', header: 'Cultural Group' },
      { field: 'ethnicity', header: 'Ethnicity' },
      { field: 'referralSource', header: 'Referrer Org' },
      { field: 'referrerDate', header: 'Referrer Date' },
      { field: 'notExist', header: 'Action' }
    ];
  }

  getCandidateDetails() {
    this.dataRetrievalService.getCandidateUserDetails(this.projectName).subscribe(
      arrayOfDetails => {
        const candidateDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {
              candidateDetails.push(
                {
                  projectName: (item.attributes.hasOwnProperty('projectName')) ? item.attributes.projectName : this.projectName,
                  projectVersion: (item.attributes.hasOwnProperty('projectVersion')) ? item.attributes.projectVersion : '1.0.0',
                  timezone: (item.attributes.hasOwnProperty('timezone')) ? item.attributes.timezone : 'Pacific/Auckland',
                  mobile: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null,
                  name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                  category: (item.attributes.hasOwnProperty('category')) ? item.attributes.category : null,
                  childDob: (item.attributes.hasOwnProperty('childDob')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.childDob) : null,
                  childName: (item.attributes.hasOwnProperty('childName')) ? item.attributes.childName : null,
                  childGender: (item.attributes.hasOwnProperty('childGender')) ? item.attributes.childGender : null,
                  estimatedDueDate: (item.attributes.hasOwnProperty('estimatedDueDate')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.estimatedDueDate) : null,
                  version: (item.attributes.hasOwnProperty('version')) ? item.attributes.version : null,
                  culturalGroup: (item.attributes.hasOwnProperty('version')) ?
                    item.attributes.version.substr(0, item.attributes.version.indexOf(':')) : null,
                  region: (item.attributes.hasOwnProperty('region')) ? item.attributes.region : null,
                  otherRegion: (item.attributes.hasOwnProperty('otherRegion')) ? item.attributes.otherRegion : null,
                  ethnicity: (item.attributes.hasOwnProperty('ethnicity')) ? item.attributes.ethnicity : null,
                  otherEthnicity: (item.attributes.hasOwnProperty('otherEthnicity')) ? item.attributes.otherEthnicity : null,
                  consentContacted: (item.attributes.hasOwnProperty('consentContacted')) ? item.attributes.consentContacted : null,
                  consentInfoToHBHF: (item.attributes.hasOwnProperty('consentInfoToHBHF')) ? item.attributes.consentInfoToHBHF : null,
                  referralSource: (item.attributes.hasOwnProperty('referralSource')) ? item.attributes.referralSource : null,
                  referrerName: (item.attributes.hasOwnProperty('referrerName')) ? item.attributes.referrerName : null,
                  referrerContact: (item.attributes.hasOwnProperty('referrerContact')) ? item.attributes.referrerContact : null,
                  referrerDate: (item.attributes.hasOwnProperty('referrerDate')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.referrerDate) : null,
                  source: 'admin',
                  registeredWay: (item.attributes.hasOwnProperty('registeredWay')) ? item.attributes.registeredWay : null,
                  notExist: undefined,
                  hidden: (item.attributes.hasOwnProperty('hidden')) ? item.attributes.hidden : null,
                }
              );
            }
          }
        );

        // hide candidates with hidden lables
        this.candidateDetails = candidateDetails.filter(candidate => candidate.hidden !== 'Y');
        this.loading = false;

        // check candidate mobile exists in User Recipient Table
        this.checkCandidateMobilesExist();
      }
    );
  }

  checkCandidateMobilesExist() {
    this.candidateDetails.forEach(candidate => {
      this.dataRetrievalService.getUserDetailsWithMobile(this._projectName,  encodeURI(candidate.mobile)).subscribe(
        response => {
          candidate.notExist = !(response.hasOwnProperty('error') && response.error === `Unable to find the user details`);
        },
        error => {
          console.error('Error fetching UserDetailsWithMobile:', error);
        }
      );
    });
  }
}
