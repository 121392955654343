import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-user-details-table-textmatch',
  templateUrl: './user-details-table-textmatch.component.html',
  styleUrls: ['./user-details-table-textmatch.component.scss']
})
export class UserDetailsTableTextmatchComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  loading = true;
  maxRows = 20;
  userDetails: any[] = [];
  tableColumns: any[] = [];

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set userDataTab(userDataTab: boolean) {
    this._userDataTab = userDataTab;
    this.refreshTable();
  }

  protected _projectName: string = null;
  protected _userDataTab = false;

  @Output() goToUserDataTabEvent = new EventEmitter<string>();
  @Output() goToEditUserDataTabEvent = new EventEmitter<string>();

  goToUserDataTab(mobile: string) {
    this.goToUserDataTabEvent.emit(mobile);
  }

  goToEditUserDataTab(mobile: string) {
    this.goToEditUserDataTabEvent.emit(mobile);
  }

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {
    this.loading = true;
    this.getUserDetails();

    this.tableColumns = [
      { field: 'mobile', header: 'Mobile' },
      { field: 'name', header: 'First Name' },
      { field: 'category', header: 'Category' },
      { field: 'childDob', header: 'Child DOB' },
      // { field: 'childName', header: 'child Name' },
      // { field: 'childGender', header: 'child Gender' },
      { field: 'estimatedDueDate', header: 'Due Date' },
      { field: 'version', header: 'Version' },
      { field: 'culturalGroup', header: 'Cultural Group' },
      { field: 'region', header: 'Region' },
      { field: 'otherRegion', header: 'Other Region' },
      { field: 'ethnicity', header: 'Ethnicity' },
      { field: 'otherEthnicity', header: 'Other Ethnicity' },
      { field: 'referralSource', header: 'Referrer Org' },
      { field: 'referrerDate', header: 'Registration Date' },
    ];

    // specify columns for TextMATCH_Main
    if (this._projectName === 'TextMATCH_Main') {
      // filter out region and otherRegion
      this.tableColumns = this.tableColumns.filter(column => ['region', 'otherRegion'].indexOf(column.field) < 0);

      // add more columns
      this.tableColumns = this.tableColumns.concat([
        { field: 're_referral', header: 'Re-referral' },
        { field: 'startDate', header: 'Start Date' },
        { field: 'endDate', header: 'End Date' },
        { field: 'subscriptionStatus', header: 'Subscription Status' },
        { field: 'archived', header: 'Archived' }
        ]
      );
    }
  }

  getUserDetails() {
    this.dataRetrievalService.getUserDetailsWithOtherFieldsInSubscriptionDBTable(this.projectName,
      this._projectName === 'TextMATCH_Main' ? ['startDate', 'endDate', 'subscriptionStatus', 'archived'] : []).subscribe(
      arrayOfDetails => {
        const userDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {
              userDetails.push(
                {
                  mobile: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null,
                  name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                  category: (item.attributes.hasOwnProperty('category')) ? item.attributes.category : null,
                  childDob: (item.attributes.hasOwnProperty('childDob')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.childDob) : null,
                  // childName: (item.attributes.hasOwnProperty('childName')) ? item.attributes.childName : null,
                  // childGender: (item.attributes.hasOwnProperty('childGender')) ? item.attributes.childGender : null,
                  estimatedDueDate: (item.attributes.hasOwnProperty('estimatedDueDate')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.estimatedDueDate) : null,
                  version: (item.attributes.hasOwnProperty('version')) ? item.attributes.version : null,
                  culturalGroup: (item.attributes.hasOwnProperty('version')) ?
                    item.attributes.version.substr(0, item.attributes.version.indexOf(':')) : null,
                  region: (item.attributes.hasOwnProperty('region')) ? item.attributes.region : null,
                  otherRegion: (item.attributes.hasOwnProperty('otherRegion')) ? item.attributes.otherRegion : null,
                  ethnicity: (item.attributes.hasOwnProperty('ethnicity')) ? item.attributes.ethnicity : null,
                  otherEthnicity: (item.attributes.hasOwnProperty('otherEthnicity')) ? item.attributes.otherEthnicity : null,
                  referralSource: (item.attributes.hasOwnProperty('referralSource')) ? item.attributes.referralSource : null,
                  referrerDate: (item.attributes.hasOwnProperty('referrerDate')) ?
                    GeneralUtils.convertISODateStringToFormattedDateString(item.attributes.referrerDate) : null,
                  re_referral: (item.attributes.hasOwnProperty('re_referral')) ? item.attributes.re_referral : null,
                  startDate: (item.attributes.hasOwnProperty('startDate')) ? item.attributes.startDate : null,
                  endDate: (item.attributes.hasOwnProperty('endDate')) ? item.attributes.endDate : null,
                  subscriptionStatus: (item.attributes.hasOwnProperty('subscriptionStatus')) ? item.attributes.subscriptionStatus : null,
                  archived: (item.attributes.hasOwnProperty('archived')) ? item.attributes.archived : null,
                }
              );
            }
          }
        );
        this.userDetails = userDetails;
        this.loading = false;
      }
    );
  }
}
