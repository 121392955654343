import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SelectItem } from 'primeng/api';
import { Message } from 'primeng//api';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-user-registration-details-table',
  templateUrl: './user-registration-details-table.component.html',
  styleUrls: ['./user-registration-details-table.component.scss']
})
export class UserRegistrationDetailsTableComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }

  @Input()
  set filterMobileNo(mobileNo: string) {
    this._filterMobileNo = mobileNo;
    this.refreshTable();
  }
  get filterMobileNo(): string {
    return this._filterMobileNo;
  }

  @ViewChild('opStatus', { static: true }) opStatus: OverlayPanel;
  @ViewChild('opModule', { static: true }) opModule: OverlayPanel;

  protected _projectName: string = null;
  protected _filterMobileNo: string = null;

  loading = true;
  loadingModules = false;
  messages: Message[] = [];

  maxRows = 20;

  userRegistrationDetails: any[] = [];

  tableColumns: any[] = [];

  registrationStatusDropdown: SelectItem[] = [];
  textMatchModulesDropdown: SelectItem[] = [];
  filterTextMatchModulesDropdown: SelectItem[] = [];

  selectedRow = null;
  newStatus = null;
  newModule = null;

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {
    this.loading = true;

    this.getUserRegistrationDetails();

    this.tableColumns = [
      {field: 'recipient', header: 'Mobile'},
      {field: 'name', header: 'Name'},
      {field: 'category', header: 'Category'},
      {field: 'module', header: 'Module'},
      {field: 'offset', header: 'Program day'},
      {field: 'startDate', header: 'Start date'},
      {field: 'endDate', header: 'End date'},
      {field: 'subscriptionStatus', header: 'Status'},
    ];

    this.registrationStatusDropdown = [
      {label: 'active', value: 'active'},
      {label: 'registering', value: 'registering'},
      {label: 'withdraw', value: 'withdraw'},
      {label: 'end', value: 'end'},
    ];

    if (this._projectName.trimLeft().indexOf('TextMATCH') > -1) {
      this.loadingModules = true;
      this.getTextMatchModules();
      this.tableColumns = this.tableColumns.concat([
        {field: 'autoWithdraw', header: 'Auto-Withdraw'}
      ]);
    }
  }

  getTextMatchModules() {
    this.dataRetrievalService.getModule(this._projectName).subscribe(
      arrayOfDetails => {
        const textMatchModules = [];
        arrayOfDetails.forEach(item => {
            if (item.hasOwnProperty('attributes')
              && item.attributes.hasOwnProperty('name') && item.attributes.hasOwnProperty('description')) {
              textMatchModules.push(
                {
                  label: item.attributes.description,
                  value: item.attributes.name,
                }
              );
            }
          }
        );

        textMatchModules.sort((current, next) => {
            if (current && next && current.value && next.value) {
              return current.value.toLowerCase() < next.value.toLowerCase() ? -1 : 1;
            } else {
              return (current && current.value) ? 1 : -1;
            }
          }
        );

        this.textMatchModulesDropdown = textMatchModules;
        this.loadingModules = false;
      }, error => {
        this.messages.push({ severity: 'info', summary: 'Error:', detail: 'Get modules error with status: ' + error.status });
        this.loadingModules = false;
      }
    );
  }

  getUserRegistrationDetails() {
    this.dataRetrievalService.getUserRegistrationDetails(this._projectName).subscribe(
      arrayOfDetails => {
        let userRegistrationDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {

              userRegistrationDetails.push(
                {
                  recipient: (item.attributes.hasOwnProperty('recipient')) ? item.attributes.recipient : null,
                  name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                  category: (item.attributes.hasOwnProperty('category')) ? item.attributes.category : null,
                  module: (item.attributes.hasOwnProperty('module')) ? item.attributes.module : null,
                  offset: (item.attributes.hasOwnProperty('offset')) ? item.attributes.offset : null,
                  startDate: (item.attributes.hasOwnProperty('startDate')) ? item.attributes.startDate : null,
                  endDate: (item.attributes.hasOwnProperty('endDate')) ? item.attributes.endDate : null,
                  subscriptionStatus: (item.attributes.hasOwnProperty('subscriptionStatus')) ? item.attributes.subscriptionStatus : null,
                  autoWithdraw: (item.attributes.hasOwnProperty('autoWithdraw')) ? item.attributes.autoWithdraw : null
                }
              );
            }
          }
        );

        if (this._filterMobileNo) {
          userRegistrationDetails = userRegistrationDetails.filter(detail => detail.recipient === this.filterMobileNo);
        }

        this.userRegistrationDetails = userRegistrationDetails;
        this.loading = false;
      }
    );
  }

  openPanel(event, fieldName, rowData) {
    if (fieldName === 'subscriptionStatus') {
      this.selectedRow = rowData;
      this.newStatus = this.selectedRow.subscriptionStatus;
      this.opStatus.toggle(event);
    } else if (fieldName === 'module' && this._projectName.trimLeft().indexOf('TextMATCH') > -1) {
      this.selectedRow = rowData;
      this.newModule = this.selectedRow.module;
      this.filterTextMatchModulesDropdown = this.selectedRow.module.endsWith('B') ?
        this.textMatchModulesDropdown.filter(module => module.value.endsWith('B')) :
        this.textMatchModulesDropdown.filter(module => !module.value.endsWith('B'));
      this.opModule.toggle(event);
    }
  }

  update(updateType: string) {
    const jsonData = {
      moduleNew: updateType === 'module' ? this.newModule : null,
      module: this.selectedRow.module,
      moduleVersion: '1.0.0',
      subscriptionStatus: updateType === 'status' ? this.newStatus : this.selectedRow.subscriptionStatus,
      recipient: this.selectedRow.recipient,
      project: this._projectName,
      projectVersion: '1.0.0'
    };
    this.dataRetrievalService.putUserRegistrationData(jsonData).subscribe(
      () => {
        this.selectedRow.subscriptionStatus = updateType === 'status' ? this.newStatus : this.selectedRow.subscriptionStatus;
        this.selectedRow.module = updateType === 'module' ? this.newModule : this.selectedRow.module;
      }
    );
  }

  updateStatus() {
    if (this.newStatus && this.newStatus !== this.selectedRow.subscriptionStatus) {
      this.update('status');
      this.opStatus.hide();
    }
  }

  updateModule() {
    if (this.newModule && this.newModule !== this.selectedRow.module) {
      this.update('module');
      this.opModule.hide();
    }
  }
}
