import { Component, OnInit, Input } from '@angular/core';

import { DataRetrievalService } from '../../data-retrieval.service';
import { GeneralUtils } from '../../general-utils';

const pako = require('pako');

@Component({
  selector: 'app-outgoing-message-log-table',
  templateUrl: './outgoing-message-log-table.component.html',
  styleUrls: ['./outgoing-message-log-table.component.scss']
})
export class OutgoingMessageLogTableComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    // this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set filterMobileNo(mobileNo: string) {
    this._filterMobileNo = mobileNo;
    this.refreshTable();
  }
  get filterMobileNo(): string {
    return this._filterMobileNo;
  }

  protected _projectName: string = null;
  protected _filterMobileNo: string = null;

  loading = false;

  maxRows = 20;

  outgoingMessageLog: any[] = [];

  tableColumns: any[] = [];

  fromDate: any;
  toDate: any;

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {
    if ((this.fromDate && this.toDate) || (this._filterMobileNo)) {
      this.loading = true;

      this.getOutgoingMessageLog();

      this.tableColumns = [
        { field: 'toNumber', header: 'Mobile' },
        { field: 'msgType', header: 'Msg Type' },
        { field: 'msgSubType', header: 'Msg Sub Type' },
        { field: 'message', header: 'Message' },
        { field: 'deliveryStatus', header: 'Status' },
        { field: 'deliveryTime', header: 'Scheduled delivery time' },
        { field: 'deliveryOn', header: 'Message delivery on' },
      ];
    }
  }

  getOutgoingMessageLog() {

      const from: string = GeneralUtils.convertDateToISODateString(this.fromDate);
      const to: string = GeneralUtils.convertDateToISODateString(this.toDate);

      this.dataRetrievalService.getOutgoingMessageLogDateRangeMobile(this.projectName, from, to, this._filterMobileNo).subscribe(
      arrayOfDetails => {
        // base 64 string -> binary
        arrayOfDetails = atob(arrayOfDetails);
        try {
          // decompress gzip
          arrayOfDetails = JSON.parse(pako.inflate(arrayOfDetails, { to: 'string' }));
        } catch (err) {
          console.log(err);
        }

        const outgoingMessageLog = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {
              outgoingMessageLog.push(
                {
                  toNumber: (item.attributes.hasOwnProperty('toNumber')) ? item.attributes.toNumber : null,
                  msgType: (item.attributes.hasOwnProperty('msgType')) ? item.attributes.msgType : null,
                  msgSubType: (item.attributes.hasOwnProperty('msgSubType')) ? item.attributes.msgSubType : null,
                  message: (item.attributes.hasOwnProperty('message')) ? item.attributes.message : null,
                  deliveryStatus: (item.attributes.hasOwnProperty('deliveryStatus')) ? item.attributes.deliveryStatus : null,
                  deliveryTime: (item.attributes.hasOwnProperty('deliveryTime')) ? item.attributes.deliveryTime : null,
                  deliveryOn: (item.attributes.hasOwnProperty('deliveryOn')) ? item.attributes.deliveryOn : null,
                }
              );
            }
          }
        );
        // Logic for usere data table. default mobile no. filter moved to backend filtering
        // if (this._filterMobileNo) {
        //   outgoingMessageLog = outgoingMessageLog.filter(detail => detail.toNumber === this.filterMobileNo);
        // }

        this.outgoingMessageLog = outgoingMessageLog;
        this.loading = false;
      }
    );
  }
}
