import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-incoming-message-log-table',
  templateUrl: './incoming-message-log-table.component.html',
  styleUrls: ['./incoming-message-log-table.component.scss']
})
export class IncomingMessageLogTableComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set filterMobileNo(mobileNo: string) {
    this._filterMobileNo = mobileNo;
    this.refreshTable();
  }
  get filterMobileNo(): string {
    return this._filterMobileNo;
  }

  @ViewChild('op', { static: true }) op: OverlayPanel;

  protected _projectName: string = null;
  protected _filterMobileNo: string = null;

  loading = true;

  disableSubmit = false;

  maxRows = 20;

  incomingMessageLog: any[] = [];

  tableColumns: any[] = [];

  selectedRow = null;

  updatedMessage = null;

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {

    this.loading = true;

    this.getIncomingMessageLog();

    this.tableColumns = [
      { field: 'Received', header: 'Received Date' },
      { field: 'From', header: 'Mobile' },
      { field: 'originalMessage', header: 'Original Message' },
      { field: 'Message', header: 'Message' },
      { field: 'Status', header: 'Status' },
      { field: 'reviewed', header: 'Reviewed' },
    ];

  }

  getIncomingMessageLog() {

    this.dataRetrievalService.getIncomingMessageLog(this.projectName).subscribe(
      arrayOfDetails => {
        let incomingMessageLog = [];

        arrayOfDetails.forEach(
              item => {

                if (item.hasOwnProperty('attributes')) {

                  incomingMessageLog.push(
                    {
                      Received: (item.attributes.hasOwnProperty('Received')) ? item.attributes.Received : null,
                      From: (item.attributes.hasOwnProperty('From')) ? item.attributes.From : null,
                      originalMessage: (item.attributes.hasOwnProperty('originalMessage')) ? item.attributes.originalMessage : null,
                      Message: (item.attributes.hasOwnProperty('Message')) ? item.attributes.Message : null,
                      msgType: (item.attributes.hasOwnProperty('msgType')) ? item.attributes.msgType : null,
                      reviewed: (item.attributes.hasOwnProperty('reviewed')) ? item.attributes.reviewed : null,
                      Status: (item.attributes.hasOwnProperty('msgType') && item.attributes.hasOwnProperty('reviewed')) ?
                        ((item.attributes.msgType === 'DEFAULT' && item.attributes.reviewed === 'N') ? 'unrecognized' : null) : null,
                    }
                  );

                }

              }
          );

        if (this._filterMobileNo) {
          incomingMessageLog = incomingMessageLog.filter(detail => detail.From === this.filterMobileNo);
        }

        this.incomingMessageLog = incomingMessageLog;
        this.loading = false;
      }
    );

  }

  openPanel(event, fieldName, rowData) {
    if (fieldName === 'Message') {
      this.selectedRow = rowData;
      this.updatedMessage = this.selectedRow.Message;
      this.op.toggle(event);
    }
  }

  updateMessage() {

    this.disableSubmit = true;

    if (!this.selectedRow.originalMessage) {
      this.selectedRow.originalMessage = this.selectedRow.Message;
    }

    this.selectedRow.Message = this.updatedMessage;
    this.selectedRow.reviewed = 'Y';
    this.selectedRow.Status = (this.selectedRow.msgType === 'DEFAULT' && this.selectedRow.reviewed === 'N') ? 'unrecognized' : null;
    const receivedUTC = new Date(this.selectedRow.Received).toISOString();

    const jsonData = {
      Received: receivedUTC,
      From: this.selectedRow.From,
      Message: this.selectedRow.Message,
      projectName: this.projectName,
      projectVersion: '1.0.0',
    };
    this.dataRetrievalService.postUpdatedMessage(jsonData).subscribe(
      response => {
        this.disableSubmit = false;
      }
    );
    this.op.hide();
  }


}
