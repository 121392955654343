import { SortEvent } from 'primeng/api';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';

// All NZ mobile numbers have between 9 and 11 digits, including a 2- or 3-digit mobile code beginning with 02.
// e.g: 21 1234 5678; 22 1234 56; 23 1234 567;
export const NZMobileNoPattern = /^2\d{1}\s\d{4}\s\d{2,4}$/;
export const mobileNoPattern = /^\+[0-9]*$/;
export const mobileNoPatternTwoFormat = /^\+[0-9]*$|^0[0-9]*$/;  // +64271234567 or 0271234567

// AWS cognito requirements
// tslint:disable-next-line:max-line-length
export const PasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+={}|[\];:',./<>?’])[A-Za-z\d`~!@#$%^&*()-_+={}|[\];:',./<>?’]{8,}$/;

export const EmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

export class GeneralUtils {

  // Function for filtering unique mobile numbers from list
  // this.mobileNumbers = this.uniqueBy(mobileNumbers, x => x.mobile);
  public static uniqueBy(arr, fn) {
    const unique = {};
    const distinct = [];
    arr.forEach(
      x => {
        const key = fn(x);
        if (!unique[key]) {
          distinct.push(x);
          unique[key] = true;
        }
      }
    );
    return distinct;
  }

  public static customSort(event: SortEvent) {
    // event.data = Data to sort
    // event.mode = 'single' or 'multiple' sort mode
    // event.field = Sort field in single sort
    // event.order = Sort order in single sort
    // event.multiSortMeta = SortMeta array in multiple sort

    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {

        if (event.field.endsWith('Date') || event.field.endsWith('Dob')) {
          const x = value1.split('/');
          const y = value2.split('/');
          value1 = x[2] + x[1] + x[0];
          value2 = y[2] + y[1] + y[0];
        }

        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }

  // Function to allow only numbers in input field
  public static numberInputValidator(event: any) {
    if (!mobileNoPattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9+]/g, '');
      // invalid character, prevent input
    }
  }

  // Convert date object to ISO date string ('yyyyMMdd')
  public static convertDateToISODateString(d: Date): string {
    if (d) {
      const day: string = ('0' + d.getDate()).slice(-2);
      const month: string = ('0' + (d.getMonth() + 1)).slice(-2);
      const year: string = ('000' + d.getFullYear()).slice(-4);

      return year + month + day;
    } else {
      return null;
    }
  }

  // Convert date object to ISO date string ('yyyyMMdd')
  public static convertISODateStringToDate(d: string): Date {
    if (d) {
      const date = new Date();
      date.setFullYear(+d.slice(0, 4), +d.slice(4, 6) - 1, +d.slice(6));
      return date;
    } else {
      return null;
    }
  }

  // convert ISO date string ('yyyyMMdd') to display formatted date string ('dd/MM/yy')
  public static convertISODateStringToFormattedDateString(d: string)  {
    const year = d.substr(0, 4);
    const month = d.substr(4, 2);
    const day = d.substr(6, 2);

    return day + '/' + month + '/' + year.substr(2, 2);
  }

  // convert display formatted date string ('dd/MM/yy') to ISO date string ('yyyyMMdd')
  public static convertFormattedDateStringToISODateString(d: string): string {
    if (d) {
      const parts = d.split('/');
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];

      const adjustedYear = '20' + year;
      return  adjustedYear + month + day;
    } else {
      return null;
    }
  }

  // Get username from token
  public static getCognitoUsername(): string {
    const token = JSON.parse(sessionStorage.getItem('token'));
    return (token) ? token.payload['cognito:username'] : null;
  }

  // Get payload from token
  public static getCognitoPayload(): object {
    const token = JSON.parse(sessionStorage.getItem('token'));
    return (token) ? token.payload : null;
  }

  public static addMobileNoCountryCode(mobileNo: string): string {
    let covertedMobileNo: string;
    if (mobileNo.startsWith('0')) {
      covertedMobileNo = '+64' + mobileNo.substring(1, mobileNo.length);
    } else {
      covertedMobileNo = mobileNo;
    }

    return covertedMobileNo;
  }

  public static exportCSV(data: any): void {
    // 1. Map field names to header names
    const headerMap = {};
    data._columns.forEach(column => {
      headerMap[column.field] = column.header;
    });

    // 2. Transform data
    const transformedData = data._value.map(item => {
      const newItem = {};
      for (const key in item) {
        if (headerMap[key]) {
          newItem[headerMap[key]] = item[key];
        }
      }
      return newItem;
    });

    // 3. Prepare CSV content
    const worksheet = xlsx.utils.json_to_sheet(transformedData);
    const csvBuffer: string = xlsx.utils.sheet_to_csv(worksheet, { FS: ',', RS: '\n', forceQuotes: true });

    // 4. Save buffer to a CSV file
    const BOM = '\uFEFF'; // Inclusion of the Byte Order Mark (BOM) resolves display issues with Chinese characters in downloaded CSV files.
    const blob: Blob = new Blob([BOM + csvBuffer], { type: 'application/csv;charset=utf-8' });
    fileSaver.saveAs(blob, 'download.csv');
  }
}
