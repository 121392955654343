import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  isAuthenticated() {
    
    let token = JSON.parse(sessionStorage.getItem("token"));
    
    return token !== null;

  }

}
