// Unused component.

import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  items: MenuItem[];

  constructor() { }

  ngOnInit() {

    this.items = [
        {
          label: 'User details',
          icon: 'pi pi-fw pi-users',            
        },
        {
          label: 'Registration details',
          icon: 'pi pi-fw pi-file',            
        },
        {
          label: 'Outgoing message log',
          icon: 'pi pi-fw pi-window-maximize',            
        },
        {
          label: 'Incoming message log',
          icon: 'pi pi-fw pi-window-minimize',            
        },        
        {
          label: 'Mobile number mapping',
          icon: 'pi pi-fw pi-clone',            
        },
        {
          label: 'Send adhoc message',
          icon: 'pi pi-fw pi-envelope',            
        },
        {
          label: 'New user registration',
          icon: 'pi pi-fw pi-user-plus',
          items: [
            { label: 'Patient', icon: 'pi pi-fw pi-user' },
            { label: 'Family', icon: 'pi pi-fw pi-user' },
          ]            
        },
    ];

  }

}
