import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SelectItem } from 'primeng/api';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-mobile-number-mapping-table',
  templateUrl: './mobile-number-mapping-table.component.html',
  styleUrls: ['./mobile-number-mapping-table.component.scss']
})
export class MobileNumberMappingTableComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @ViewChild('op1', { static: true }) op1: OverlayPanel;
  @ViewChild('op2', { static: true }) op2: OverlayPanel;
  @ViewChild('op3', { static: true }) op3: OverlayPanel;

  protected _projectName: string = null;

  loading = true;

  disableSubmit = false;

  maxRows = 20;

  mobileNumbers: SelectItem[] = [];

  mobileMapping: any[] = [];

  tableColumns: any[] = [];

  selectedRow: any = null;

  mappingStatusDropdown: SelectItem[] = [];

  newMobile: string = null;
  selectedStatus: any = null;

  newMobileMapping: any = {
    mobileOriginal: null,
    mobileNew: null,
    projectName: this.projectName,
    projectVersion: '1.0.0',
    status: null
  };

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {

    this.loading = true;

    this.getMobileNumbers();
    this.getMobileMapping();

    this.tableColumns = [
      { field: 'projectName', header: 'Project' },
      { field: 'mobileOriginal', header: 'Original Mobile' },
      { field: 'mobileNew', header: 'New Mobile' },
      { field: 'status', header: 'Status' },
    ];

    this.mappingStatusDropdown = [
      { label: 'active', value: 'active' },
      { label: 'inactive', value: 'inactive' },
    ];

  }

  getMobileNumbers() {
    this.dataRetrievalService.getUserDetails(this.projectName).subscribe(
      arrayOfDetails => {
        const mobileNumbers = [{ label: 'Select a mobile number...', value: null }];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {
              mobileNumbers.push(
                {
                  label: (item.attributes.hasOwnProperty('mobile')) ? (
                    item.attributes.hasOwnProperty('name') ?
                      item.attributes.mobile + ' (' + item.attributes.name + ')' : item.attributes.mobile
                    ) : null,
                  value: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null
                }
              );
            }
          }
        );
        this.mobileNumbers = mobileNumbers;
      }
    );
  }

  getMobileMapping() {
    this.dataRetrievalService.getMobileMapping(this.projectName).subscribe(
      arrayOfDetails => {
        const mobileMapping = [];
        arrayOfDetails.forEach(
            item => {
              if (item.hasOwnProperty('attributes')) {
                mobileMapping.push(
                  {
                    projectName: (item.attributes.hasOwnProperty('projectName')) ? item.attributes.projectName : null,
                    mobileOriginal: (item.attributes.hasOwnProperty('mobileOriginal')) ? item.attributes.mobileOriginal : null,
                    mobileNew: (item.attributes.hasOwnProperty('mobileNew')) ? item.attributes.mobileNew : null,
                    status: (item.attributes.hasOwnProperty('status')) ? item.attributes.status : null,
                  }
                );
              }
            }
          );
        this.mobileMapping = mobileMapping;
        this.loading = false;
      }
    );
  }

  openPanel(event, fieldName, rowData) {
    if (fieldName === 'mobileNew') {
      this.selectedRow = rowData;
      this.newMobile = this.selectedRow.mobileNew;
      this.op1.toggle(event);
    } else if (fieldName === 'status') {
      this.selectedRow = rowData;
      this.selectedStatus = this.selectedRow.status;
      this.op2.toggle(event);
    }
  }

  openNewMobile(event) {
    this.newMobileMapping = {
      mobileOriginal: null,
      mobileNew: null,
      projectName: this.projectName,
      projectVersion: '1.0.0',
      status: null
    };
    this.op3.toggle(event);
  }

  updateMobile() {
    this.disableSubmit = true;
    this.selectedRow.mobileNew = this.newMobile;
    const jsonData = {
      mobileOriginal: this.selectedRow.mobileOriginal,
      mobileNew: this.selectedRow.mobileNew,
      projectName: this.projectName,
      projectVersion: '1.0.0',
      status: this.selectedRow.status
    };
    this.dataRetrievalService.putMobileMapping(jsonData).subscribe(
      response => {
        this.disableSubmit = false;
      }
    );
    this.op1.hide();
  }

  updateStatus() {
    this.disableSubmit = true;
    this.selectedRow.status = this.selectedStatus;
    const jsonData = {
      mobileOriginal: this.selectedRow.mobileOriginal,
      mobileNew: this.selectedRow.mobileNew,
      projectName: this.projectName,
      projectVersion: '1.0.0',
      status: this.selectedRow.status
    };
    this.dataRetrievalService.putMobileMapping(jsonData).subscribe(
      response => {
        this.disableSubmit = false;
      }
    );
    this.op2.hide();
  }

  submitNewMobile() {
    if (this.newMobileMapping.mobileOriginal !== null && this.newMobileMapping.mobileNew !== null) {

      this.disableSubmit = true;
      this.op3.hide();
      this.loading = true;

      this.dataRetrievalService.putMobileMapping(this.newMobileMapping).subscribe(
        response => {
          this.disableSubmit = false;
          this.refreshTable();
        }
      );
    }
  }

  numberInputValidator(event: any) {
    GeneralUtils.numberInputValidator(event);
  }
}
