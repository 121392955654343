import { Component, OnInit } from '@angular/core';
import { CognitoUsersService } from '../../cognito-users.service';
import { Message } from 'primeng//api';
import { EmailPattern, PasswordPattern } from '../../general-utils';

@Component({
  selector: 'app-register-admin-textmatch',
  templateUrl: './register-admin-textmatch.component.html',
  styleUrls: ['./register-admin-textmatch.component.scss']
})
export class RegisterAdminTextmatchComponent implements OnInit {
  fullName: string;
  email: string;
  emailPattern = EmailPattern;
  password: string;
  confirmPassword: string;
  passwordPattern = PasswordPattern;
  organisation: any;

  organisationOptions = [
    { name: 'CNSST Foundation', value: 'CNSST' },
    { name: 'HealthWEST', value: 'HealthWest' },
    { name: 'The Fono', value: 'The Fono' },
    { name: 'The Asian Network Inc. (TANI)', value: 'TANI'},
    { name: 'National Institute for Health Innovation (NIHI)', value: 'NIHI'},
  ];

  passwordVisibility = false;
  confirmPasswordVisibility = false;

  registeredSuccess = false;
  messages: Message[] = [];

  constructor(protected cognitoUsersService: CognitoUsersService) { }

  ngOnInit() {}

  togglePasswordVisibility(index: number) {
    if (index === 0) {
      this.passwordVisibility = !this.passwordVisibility;
    } else if (index === 1) {
      this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
    }
  }

  register() {
    this.cognitoUsersService.register_textmatch(this.email, this.password, this.fullName, this.organisation.name, this.callbackFunc);
  }

  callbackFunc = (err, result) => {
    if (err) {
      this.messages = [];
      this.messages.push({ severity: 'warn', summary: 'Registration error:',
        detail: err.message });
    } else {
      this.registeredSuccess = true;
    }
  }
}
