import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-element-error',
  template: `
    <div class="ui-g-{{totColumnSize}}" style="display: flex; align-items: center; color: red; padding: 0px">
      <div class="ui-g-{{lblColSize}}" style="padding: 0px"></div>
      <div class="ui-g-{{valColSize}}" style="padding: 0px 7px">
        {{errorMsgVal}}
      </div>
    </div>
  `,
  styles: []
})
export class FormElementErrorComponent implements OnInit {

  errorMsgVal: string;

  lblColSize = 2;

  totColumnSize = 12;

  valColSize: any;

  constructor() { }

  ngOnInit() {
    this.valColSize = this.totColumnSize - this.lblColSize;
  }

  @Input()
  set errorMsg(errorMsgVal: string) {
    this.errorMsgVal = errorMsgVal;
  }

  @Input()
  set totalColumnSize(totalColumnSize: any) {
    this.totColumnSize = totalColumnSize;
  }

  @Input()
  set labelColumnSize(labelColumnSize: any) {
    this.lblColSize = labelColumnSize;
  }

}
