import {Component, Input, OnInit} from '@angular/core';
import {Message} from 'primeng//api';
import {DataRetrievalService} from '../../data-retrieval.service';
import {GeneralUtils} from '../../general-utils';

@Component({
  selector: 'app-switch-program-textmatch',
  templateUrl: './switch-program-textmatch.component.html',
  styleUrls: ['./switch-program-textmatch.component.scss']
})
export class SwitchProgramTextmatchComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.clearFormInputs();
    this.getRegistrationDropdownOptions();
  }
  get projectName(): string {
    return this._projectName;
  }

  protected _projectName: string = null;

  disableSubmit = false;

  loading = true;
  mobileList: any[] = [];
  mobile: any;

  messages_1: Message[];
  messages_2: Message[];

  dropdownOptions: any;
  enteredFormInput: any;

  userDetails: any[] = [];
  userRegistrationDetails: any[] = [];

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {
  }

  getUserDetails() {
    this.loading = true;
    this.dataRetrievalService.getUserDetails(this.projectName).subscribe(
      arrayOfDetails => {
        const userDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('mobile')) {
              userDetails.push(
                {
                  mobile: item.attributes.mobile,
                  name: item.attributes.name,
                }
              );
            }
          }
        );
        this.userDetails = userDetails;
        this.getUserRegistrationDetails();
      }
    );
  }

  getUserRegistrationDetails() {
    this.dataRetrievalService.getUserRegistrationDetails(this._projectName).subscribe(
      arrayOfDetails => {
        const userRegistrationDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('recipient') &&
              item.attributes.hasOwnProperty('module') && item.attributes.hasOwnProperty('subscriptionStatus')) {

              userRegistrationDetails.push(
                {
                  recipient: item.attributes.recipient,
                  module: item.attributes.module,
                  subscriptionStatus: item.attributes.subscriptionStatus,
                }
              );
            }
          }
        );

        this.userRegistrationDetails = userRegistrationDetails.filter(detail => !detail.module.endsWith('B')
           && (detail.subscriptionStatus.indexOf('active') > -1 || detail.subscriptionStatus.indexOf('non-switch') > -1));

        this.mergeLists();
      }
    );
  }

  mergeLists() {
    const mergedList = [];
    this.userRegistrationDetails.forEach(registration => {
      mergedList.push({
        ...registration,
        ...(this.userDetails.find((itmInner) => itmInner.mobile === registration.recipient))}
      );
    });

    this.mobileList.push({
      label: 'Select a mobile number...',
      value: null
    });

    mergedList.forEach( registration => {
      const mobileNo = (registration.name && registration.subscriptionStatus) ?
        registration.mobile + ` (name: ${registration.name} - status: ${registration.subscriptionStatus})`
        : registration.mobile;

      this.mobileList.push({
        label: mobileNo,
        value: registration.mobile
      });
      }
    );

    this.loading = false;
  }

  clearFormInputs() {
    this.mobile = null;
    this.mobileList = [];
    this.enteredFormInput = {
      childDob: null,
      childGender: null,
      childName: null,
    };
    this.getUserDetails();
  }

  getRegistrationDropdownOptions() {
    this.dataRetrievalService.getRegistrationDropdownOptions().subscribe(
      dropdownOptions => {
        this.dropdownOptions = dropdownOptions;
      }
    );
  }

  submitSwitch() {

    this.disableSubmit = true;
    this.messages_1 = [];
    this.messages_2 = [];
    this.messages_2.push({ severity: 'info', summary: 'Sending:', detail: 'Submitting registration, please wait...' });

    const switchData = {
      recipient: this.mobile,
      project: this.projectName,
      projectVersion: '1.0.0',
      childDob: GeneralUtils.convertDateToISODateString(this.enteredFormInput.childDob),
      childGender: this.enteredFormInput.childGender,
      childName: this.enteredFormInput.childName,
    };

    this.dataRetrievalService.putSubscriptionSwitchProgram(switchData)
      .subscribe(response => {
          this.messages_1 = [];
          this.messages_2 = [];

          if (response !== null && response.hasOwnProperty('error')) {
            this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Switch program failed: ' + response.error });
          } else {
            this.messages_1.push({ severity: 'info', summary: 'Sent:', detail: 'Switch successful. You can now switch for another user.' });
            this.clearFormInputs();
            window.scrollTo(0, 0);
          }

          this.disableSubmit = false;
        },
        error => {
          this.messages_1 = [];
          this.messages_2 = [];

          this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Switch program failed. Unknown error.' });

          this.disableSubmit = false;
        }
      );
  }

  numberInputValidator(event: any) {
    GeneralUtils.numberInputValidator(event);
  }

}
