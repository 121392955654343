import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainViewComponent } from './main-view/main-view.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AdminConfirmRegistrationComponent } from './admin-confirm-registration/admin-confirm-registration.component';
import { ForceChangePWDComponent } from './force-change-pwd/force-change-pwd.component';
import { LoginTextmatchComponent } from './Project-TextMATCH/login-textmatch/login-textmatch.component';
import { RegisterAdminTextmatchComponent } from './Project-TextMATCH/register-admin-textmatch/register-admin-textmatch.component';
import { ResetPwdTextmatchComponent } from './Project-TextMATCH/reset-pwd-textmatch/reset-pwd-textmatch.component';
import { OnlineRegisterTextmatchComponent } from './Project-TextMATCH/online-register-textmatch/online-register-textmatch.component';
import { RedirectComponent } from './redirect/redirect.component';


const routes: Routes = [
  { path: 'registration', component: AdminRegistrationComponent },
  { path: 'confirm-registration', component: AdminConfirmRegistrationComponent },
  { path: 'login', component: LoginComponent },
  { path: 'force-change-password', component: ForceChangePWDComponent},

  // TextPepi
  { path: 'txtpepi', component: RedirectComponent },
  { path: 'mi/txtpepi', component: RedirectComponent },

  // TextMATCH
  { path: 'textmatch/login', component: LoginTextmatchComponent },
  { path: 'textmatch/register-admin', component: RegisterAdminTextmatchComponent },
  { path: 'textmatch/reset-password', component: ResetPwdTextmatchComponent },

  // accessible after login
  { path: 'main-view', component: MainViewComponent, canActivate: [ AuthGuardService ] },
  { path: 'textmatch/online-register', component: OnlineRegisterTextmatchComponent, canActivate: [ AuthGuardService ] },

  { path: '', redirectTo: '/main-view', pathMatch: 'full' },
  { path: '**', redirectTo: '/main-view', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
