import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DataRetrievalService } from 'src/app/data-retrieval.service';
import { NewRegistrationTextmatchLakesComponent } from '../new-registration-textmatch-lakes/new-registration-textmatch-lakes.component';

@Component({
  selector: 'app-new-registration-textmatch-lakes-maori',
  templateUrl: './new-registration-textmatch-lakes-maori.component.html',
  styleUrls: ['./new-registration-textmatch-lakes-maori.component.scss']
})
export class NewRegistrationTextmatchLakesMaoriComponent extends NewRegistrationTextmatchLakesComponent implements OnInit {

  protected version = 'mi';

  constructor(protected dataRetrievalService: DataRetrievalService,
              protected formBuilder: FormBuilder,
              protected cdRef: ChangeDetectorRef,
              protected titleService: Title) {
    super(dataRetrievalService, formBuilder, cdRef, titleService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getDropdownOptions() {
    this.optionsRegion = [
      { label: 'Kōwhiri i tō wāhi', value: null },
      { label: 'Rotorua', value: 'rotorua' },
      { label: 'Taupō', value: 'taupo' },
      { label: 'Turangi', value: 'turangi' },
      { label: 'Mangakino', value: 'mangakino' },
      { label: 'Tairāwhiti District (e.g. Gisborne)', value: 'tairawhiti' },
      { label: 'Tētahi atu', value: 'other' },
    ];

    this.optionsRotoruaSuburb = [
      { label: 'Kōwhiri i tō rohe', value: null },
      { label: 'Fairy Springs', value: 'fairySprings'},
      { label: 'Fenton Park', value: 'fentonPark'},
      { label: 'Fordlands', value: 'fordlands'},
      { label: 'Glenholme', value: 'glenholme'},
      { label: 'Hannahs Bay', value: 'hannahsBay'},
      { label: 'Hillcrest', value: 'hillcrest'},
      { label: 'Holdens Bay', value: 'holdensBay'},
      { label: 'Kawaha Point', value: 'kawahaPoint'},
      { label: 'Koutu', value: 'koutu'},
      { label: 'Lynmore', value: 'lynmore'},
      { label: 'Ngapuna', value: 'ngapuna'},
      { label: 'Ngongotaha', value: 'ngongotaha'},
      { label: 'Ohinemutu', value: 'ohinemutu'},
      { label: 'Owhata', value: 'owhata'},
      { label: 'Pukehangi', value: 'pukehangi'},
      { label: 'Selwyn Heights', value: 'selwynHeights'},
      { label: 'Springfield', value: 'springfield'},
      { label: 'Sunnybrook', value: 'sunnybrook'},
      { label: 'Tihiotonga', value: 'tihiotonga'},
      { label: 'Utuhina', value: 'utuhina'},
      { label: 'Victoria', value: 'victoria'},
      { label: 'Western Heights', value: 'westernHeights'},
      { label: 'Whakarewarewa', value: 'whakarewarewa'}
    ];

    this.optionsEthnicity = [
      { label: 'New Zealand European', value: 'nzEuropean' },
      { label: 'Māori', value: 'maori' },
      { label: 'Samoan', value: 'samoan' },
      { label: 'Cook Island Maori', value: 'cookIsland' },
      { label: 'Tongan', value: 'tongan' },
      { label: 'Niuean', value: 'niuean' },
      { label: 'Chinese', value: 'chinese' },
      { label: 'Indian', value: 'indian' },
      { label: 'Ētahi atu, hei tauira Dutch, Japanese, Tokelauan', value: 'other' }
    ];

    this.optionsGender = [
      { label: 'Kōwhiri ira', value: null },
      { label: 'Ira Tāne', value: 'male' },
      { label: 'Ira Wahine', value: 'female' },
      { label: 'He ira atu', value: 'anotherGender' }
    ];

    this.optionsAgeGrp = [
      { label: 'Kōwhiri i tō reanga', value: null },
      { label: 'I raro iho i te 16 tau', value: 'under16' },
      { label: '17-24 tau', value: '17-24' },
      { label: '25-34 tau', value: '25-34' },
      { label: '35-44 tau', value: '35-44' },
      { label: '45-54 tau', value: '45-54' },
      { label: '55-64 tau', value: '55-64' },
      { label: '65 tau pakeke ake', value: 'over65' }
    ];

    this.optionsCategories = [
      { label: 'Kōwhiri rōpū raraunga', value: null },
      { label: 'Te wahine e hapu ana', value: 'PregnantMother' },
      { label: 'He uri nō te wahine kei te hapū', value: 'PregnantMotherFamily' },
      { label: 'He Māma he pēpi tāna kei raro iho i te 6 marama te pakeke', value: 'MotherWithBaby' },
      { label: 'He uri nō te pēpi kei raro iho i te 6 marama te pakeke', value: 'MotherWithBabyFamily'},
    ];

    this.optionsChildGender = [
      { label: 'Kōwhiria te ira i tō pēpi', value: null },
        { label: 'He tama', value: 'boy' },
        { label: 'He kōtiro', value: 'girl' },
        { label: 'Ira Tāne', value: 'Male' },
        { label: 'Ira Wahine', value: 'Female' },
    ];

    this.optionsChildRelationship = [
        { label: 'Kōwhiria te hononga i tō tamaiti', value: null },
        { label: 'Pāpā', value: 'father' },
        { label: 'Māmā', value: 'mother' },
        { label: 'Koroua', value: 'grandfather' },
        { label: 'Kuia', value: 'grandmother' },
        { label: 'Whāea Kēkē', value: 'aunty' },
        { label: 'Matua Kēkē/Pāpara', value: 'uncle' },
        { label: 'Ētahi atu whānau', value: 'other' }
    ];

    this.optionsConsent = [
       { label: 'Kōwhiri ae/kāore', value: null },
       { label: 'Āe', value: 'Y' },
       { label: 'Kāo', value: 'N' }
    ];

    this.optionsHearAbout = [
       { label: 'Nā te tākuta/ nēhi / nēhi whakawhānau pēpi', value: 'fromHealthProfessional' },
       { label: 'Pānui whakaahua i te whare haumanu', value: 'fromPoster' },
       { label: 'Whānau', value: 'whanau' },
       { label: 'Ētahi atu', value: 'other' }
    ];
  }
}
