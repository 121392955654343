import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CognitoUsersService } from '../cognito-users.service';

@Component({
  selector: 'app-admin-confirm-registration',
  templateUrl: './admin-confirm-registration.component.html',
  styleUrls: ['./admin-confirm-registration.component.scss']
})
export class AdminConfirmRegistrationComponent implements OnInit {
  email: string;
  confirmationCode: string;

  constructor(protected cognitoUsersService: CognitoUsersService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.email = this.route.snapshot.queryParams.email;
  }

  confirm() {
    this.cognitoUsersService.confirmRegistration(this.email, this.confirmationCode);
  }
}
