import { Component, OnInit, Input } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { Message, SelectItemGroup } from 'primeng//api';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-adhoc-message-form',
  templateUrl: './adhoc-message-form.component.html',
  styleUrls: ['./adhoc-message-form.component.scss'],
  providers: [ConfirmationService]
})
export class AdhocMessageFormComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.selectMultiNumbers = this._projectName.trimLeft().indexOf('TextMATCH') > -1;
    this.selectedMobileNumber = null;
    this.selectedMobileNumbers = null;
    this.getMobileNumbers();
  }
  get projectName(): string {
    return this._projectName;
  }

  protected _projectName: string = null;

  disableSubmit = false;

  messages: Message[] = [];

  groupedOptions: SelectItemGroup[] = [];
  mobileNumbers: SelectItem[] = [];

  selectedMobileNumber: SelectItem = null;
  selectedMobileNumbers: SelectItem[] = null;
  selectMultiNumbers = false;

  message: any = null;

  constructor(protected dataRetrievalService: DataRetrievalService, private confirmationService: ConfirmationService) {}

  ngOnInit() {}

  getMobileNumbers() {

    this.dataRetrievalService.getUserDetailsWithStatus(this.projectName, 'active').subscribe(
      arrayOfDetails => {
        const mobileNumbers = [];
        arrayOfDetails.forEach(
            item => {
              if (item.hasOwnProperty('attributes')) {
                mobileNumbers.push(
                  {
                    label: (item.attributes.hasOwnProperty('mobile')) ? (
                        item.attributes.hasOwnProperty('name') ?
                          item.attributes.mobile + ' (' + item.attributes.name + ')' : item.attributes.mobile
                    ) : null,
                    value: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null,
                    name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                    referralSource: (item.attributes.hasOwnProperty('referralSource')) ? item.attributes.referralSource : null,
                  }
                );
              }
            }
          );

        mobileNumbers.sort(
          (current, next) => {
            if (current && next && current.name && next.name) {
              return current.name.toLowerCase() < next.name.toLowerCase() ? 1 : -1;
            } else {
              return (current && current.name) ? -1 : 1;
            }
          }
        );

        if (!this.selectMultiNumbers) {
          mobileNumbers.unshift({ label: 'Select a mobile number...', value: null, name: null });
        }

        this.mobileNumbers = mobileNumbers;

        // if TextMATCH_Main, add referralSource group items
        if (this.mobileNumbers && this._projectName.trimLeft() === 'TextMATCH_Main') {
          // group the data by referralSource
          const groupedData = {};
          mobileNumbers.forEach(item => {
            if (!groupedData[item.referralSource]) {
              groupedData[item.referralSource] = [];
            }
            groupedData[item.referralSource].push(item);
          });

          const groupedOrganisations = Object.keys(groupedData).map(referralSource => ({
            label: referralSource,
            value: groupedData[referralSource].map(item => item.value),
          }));

          this.groupedOptions = [
            {
              label: 'Send a group message by referral organisation', value: 'group',
              items: groupedOrganisations,
            },
            {
              label: 'Send to individual contacts', value: 'individual',
              items: this.mobileNumbers,
            }
          ];
        }
      }
    );
  }

  sendMessageConfirm() {
    if (this.selectedMobileNumbers && this.selectedMobileNumbers.length > 1) {
      // multiple target mobile numbers
      this.confirmationService.confirm({
        message: 'Are you sure you want to send a message to multiple users?',
        header: 'Send to multiple users confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
            this.sendMessage();
        },
        reject: () => {
        }
      });
    }
    else {
      this.sendMessage();
    }
  }

  sendMessage() {
    this.disableSubmit = true;
    this.messages = [];
    this.messages.push({ severity: 'info', summary: 'Sending:', detail: 'Sending message, please wait...' });
    const toNumberOrNumbers = this.selectedMobileNumber ? this.selectedMobileNumber :
      (this.selectedMobileNumbers ? this.selectedMobileNumbers.filter(item => !Array.isArray(item)) : null);

    const adhocMessage = {
      project: this.projectName,
      projectVersion: '1.0.0',
      fromNumber: '8720',
      message: this.message,
      toNumber: toNumberOrNumbers
    };

    this.dataRetrievalService.postAdhocMessage(adhocMessage).subscribe(
      response => {
        this.messages = [];
        this.messages.push({ severity: 'info', summary: 'Sent:', detail: 'Message sent.' });
        this.disableSubmit = false;
      }
    );
  }

  multiSelectOnChange(event: any) {
    // if a group is selected
    if (event.itemValue && Array.isArray(event.itemValue)) {
      const optionsTicked = this.selectedMobileNumbers.includes(event.itemValue);

      if (optionsTicked) { // tick
        // add unique items
        this.selectedMobileNumbers = [...new Set([...this.selectedMobileNumbers, ...event.itemValue])];
        // disable all individual options
        this.mobileNumbers.map(item => item.disabled = true);
      } else { // untick
        // remove items
        this.selectedMobileNumbers = this.selectedMobileNumbers.filter(item => !event.itemValue.includes(item));
        // if no group is selected now, enable all individual options
        if (!this.selectedMobileNumbers.some(item => Array.isArray(item))) {
          this.mobileNumbers.map(item => item.disabled = false);
        }
      }
    }
  }
}
