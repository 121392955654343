import { Component, OnInit, Input } from '@angular/core';
import {Message} from 'primeng//api';
import {DataRetrievalService} from '../../data-retrieval.service';

export class UserDetail {
  mobile: string;
  name: string;
  archived: string;
  status: string;
  module: string;
}

@Component({
  selector: 'app-archive-user-textmatch',
  templateUrl: './archive-user-textmatch.component.html',
  styleUrls: ['./archive-user-textmatch.component.scss']
})
export class ArchiveUserTextmatchComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.clearFormInputs();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set archiveMobileNO(mobileNO: string) {
    this.mobile = mobileNO;
  }
  get archiveMobileNO(): string {
    return this.mobile;
  }

  protected _projectName: string = null;

  disableSubmit = false;

  loading = true;
  needSwitchStatus: boolean = undefined;

  mobileList: any[] = [];
  mobile: any;

  userDetails: UserDetail[] = [];

  messages: Message[];


  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {
  }

  clearFormInputs() {
    this.needSwitchStatus = undefined;
    this.mobile = null;
    this.mobileList = [];
    this.getUserDetailsWithStatus();
  }

  getUserDetailsWithStatus() {
    this.loading = true;
    this.dataRetrievalService.getUserDetailsWithOtherFieldsInSubscriptionDBTable(
      this.projectName, ['subscriptionStatus', 'module']).subscribe(
      arrayOfDetails => {
        const userDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('mobile')) {
              userDetails.push(
                {
                  mobile: item.attributes.mobile,
                  name: item.attributes.name,
                  archived: item.attributes.archived,
                  module: item.attributes.module,
                  status: item.attributes.subscriptionStatus
                }
              );
            }
          }
        );

        this.userDetails = userDetails.filter(detail => (detail.archived === undefined));

        this.mobileList.push({
          label: 'Select a mobile number...',
          value: null
        });

        this.userDetails.forEach(element => {
          const mobileNo = (element.name) ?
            element.mobile + ` (name: ${element.name})`
            : element.mobile;

          this.mobileList.push({
            label: mobileNo,
            value: element.mobile
          });
        });

        this.loading = false;

        if (this.mobile) {
          this.checkActiveStatus();
        }
      }
    );
  }

  // if status is 'active', need to change to 'withdraw'
  checkActiveStatus() {
    const userDetails = this.userDetails.filter(u => u.mobile === this.mobile);
    this.needSwitchStatus = userDetails.length > 0 && userDetails[0].status === 'active';
    return this.needSwitchStatus;
  }

  // withdraw the user when the status is 'active'
  withDrawUser() {
    const userIndex = this.userDetails.findIndex(u => u.mobile === this.mobile);
    if (userIndex >= 0 && this.userDetails[userIndex].status === 'active') {
      const user = this.userDetails[userIndex];
      const jsonData = {
        module: user.module,
        moduleVersion: '1.0.0',
        subscriptionStatus: 'withdraw',
        recipient: user.mobile,
        project: this._projectName,
        projectVersion: '1.0.0'
      };
      this.dataRetrievalService.putUserRegistrationData(jsonData).subscribe(
        () => {
          this.userDetails[userIndex].status = 'withdraw';
          this.needSwitchStatus = false;
        }
      );
    }
  }

  submitArchive() {
    this.disableSubmit = true;
    this.messages = [];
    this.messages.push({ severity: 'info', summary: 'Sending:', detail: 'Submitting Archive User, please wait...' });

    const postData = {
      recipient: this.mobile,
      project: this.projectName,
      projectVersion: '1.0.0'
    };

    this.dataRetrievalService.postArchiveUser(postData)
      .subscribe(response => {
          this.messages = [];

          if (response !== null && response.hasOwnProperty('error')) {
            this.messages.push({ severity: 'info', summary: 'Error:', detail: 'Archive User failed: ' + response.error });
          } else {
            this.messages.push({ severity: 'info', summary: 'Sent:', detail: 'Archive User successful.' });
            this.clearFormInputs();
            window.scrollTo(0, 0);
          }

          this.disableSubmit = false;
        },
        error => {
          this.messages = [];
          this.messages.push({ severity: 'info', summary: 'Error:', detail: 'Archive User failed: ' + error });

          this.disableSubmit = false;
        }
      );
  }
}
