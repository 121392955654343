import {Component, Input, OnInit} from '@angular/core';
import {Message} from 'primeng//api';
import {GeneralUtils, mobileNoPattern} from '../../general-utils';
import {DataRetrievalService} from '../../data-retrieval.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-new-registration-textmatch',
  templateUrl: './new-registration-textmatch.component.html',
  styleUrls: ['./new-registration-textmatch.component.scss']
})
export class NewRegistrationTextmatchComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.clearFormInputs();
    this.getRegistrationDropdownOptions();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set filterMobileNo(mobileNo: string) {
    this._filterMobileNo = mobileNo;
  }

  @Input()
  set userDetailData(userData: any) {
    this._newUserData = userData;
  }

  protected _projectName: string = null;
  protected _filterMobileNo: string = null;
  protected _newUserData: any = null;

  disableSubmit = false;

  messages_1: Message[];
  messages_2: Message[];

  dropdownOrganisation_Main: any;
  dropdownOptions: any;
  dropdownCategories: any;
  dropdownTextMATCHVersions: any;
  dropdownLakesTeReoEthnicity: any;
  dropdownLakesEnglishEthnicity: any;
  selfRegistration = false;
  enteredFormInput: any;

  userUpdate = false;

  checkingMobile = false;
  mobilePattern = mobileNoPattern;
  mobileHasRegistered: boolean;

  today = new Date();

  constructor(protected dataRetrievalService: DataRetrievalService, private route: ActivatedRoute) { }

  ngOnInit() {
    if (!this._projectName) {
      this._projectName = this.route.snapshot.queryParams.projectName;
      this.selfRegistration = true;
      this.clearFormInputs();
      this.getRegistrationDropdownOptions();
    }

    if (this._filterMobileNo) {
      this.getUserRegistrationData();
    }

    if (this._newUserData) {
      this.prepareUserRegistrationData();
    }
  }

  clearFormInputs() {
    this.enteredFormInput = {
      preferredName: null,
      preferredMobileNumber: null,
      confirmMobileNumber: null,
      category: null,
      estimatedDueDate: null,
      childDob: null,
      childGender: null,
      childName: null,
      version: null,
      ethnicity: null,
      consent: 'Y',
      consentContacted: null,
      consentInfoToHBHF: null,
      referralSource: null,
      referrerName: null,
      referrerContact: null,
      referrerDate: this.today,
      re_referral: 'N',
      registeredWay: null
    };
    this.userUpdate = false;
  }

  getRegistrationDropdownOptions() {
    this.dataRetrievalService.getRegistrationDropdownOptions().subscribe(
      dropdownOptions => {
        this.dropdownOptions = dropdownOptions;

        this.dropdownOrganisation_Main = [
          { label: 'Select organisation...', value: null },
          { label: 'HealthWest', value: 'HealthWest' },
          { label: 'The Fono', value: 'The Fono' },
          { label: 'CNSST', value: 'CNSST' },
          { label: 'TANI', value: 'TANI'},
          { label: 'NIHI', value: 'NIHI'},
          { label: 'Self-referral', value: 'Self-referral'},
        ];

        this.dropdownCategories = [
          { label: 'Select category...', value: null },
          { label: 'Pregnant mother', value: 'PregnantMother' },
          { label: 'Family member of pregnant mother', value: 'PregnantMotherFamily' },
          { label: 'Mother of baby under 21 months', value: 'MotherWithBaby' },
          { label: 'Family member of mother of baby under 21 months', value: 'MotherWithBabyFamily'},
        ];

        this.dropdownTextMATCHVersions = this._projectName.trimLeft().toUpperCase() === 'TEXTMATCH_MAIN' ? [
          { label: 'Select version...', value: null },
          { label: 'Maori: Te Reo', value: 'Maori: Te Reo' },
          { label: 'Maori: English', value: 'Maori: English' },
          { label: 'Pacific: English', value: 'Pacific: English' },
          { label: 'Asian: Chinese', value: 'Asian: Chinese' },
          { label: 'Asian: Japanese', value: 'Asian: Japanese' },
          { label: 'Asian: Korean', value: 'Asian: Korean' },
          { label: 'Asian: English', value: 'Asian: English' },
          { label: 'South Asian: English', value: 'South Asian: English' }
        ] : [
          { label: 'Select version...', value: null },
          { label: 'Lakes: Te Reo', value: 'Lakes: Te Reo' },
          { label: 'Lakes: English', value: 'Lakes: English' }
        ];

        this.dropdownLakesTeReoEthnicity = [
          { label: 'Select ethnicity...', value: null },
          { label: 'Maori', value: 'Maori' },
        ];

        this.dropdownLakesEnglishEthnicity = [
          { label: 'Select ethnicity...', value: null },
          { label: 'Other', value: 'Other' },
        ];
      }
    );
  }

  setUserData(user: any) {
    this.clearFormInputs();
    this.enteredFormInput.preferredName = user.name;
    this.enteredFormInput.preferredMobileNumber = user.mobile;
    this.enteredFormInput.confirmMobileNumber = user.mobile;
    this.enteredFormInput.category = user.category;
    this.enteredFormInput.estimatedDueDate = user.estimatedDueDate ? GeneralUtils.convertISODateStringToDate(user.estimatedDueDate) : null;
    this.enteredFormInput.childDob = user.childDob ? GeneralUtils.convertISODateStringToDate(user.childDob) : null;
    this.enteredFormInput.childGender = user.childGender;
    this.enteredFormInput.childName = user.childName;
    this.enteredFormInput.version = user.version;
    this.enteredFormInput.ethnicity = user.ethnicity;
    this.enteredFormInput.consentContacted = user.consentContacted;
    this.enteredFormInput.consentInfoToHBHF = user.consentInfoToHBHF;
    this.enteredFormInput.referralSource = user.referralSource;
    this.enteredFormInput.referrerName = user.referrerName;
    this.enteredFormInput.referrerContact = user.referrerContact;
    this.enteredFormInput.referrerDate = user.referrerDate ? GeneralUtils.convertISODateStringToDate(user.referrerDate) : null;
    this.enteredFormInput.re_referral = user.re_referral;
    this.enteredFormInput.registeredWay = user.registeredWay;
  }

  prepareUserRegistrationData() {
    this.setUserData(this._newUserData);
    this.enteredFormInput.consent = 'Y';
    this.userUpdate = false;
    this.selfRegistration = false;
  }

  getUserRegistrationData() {
    this.dataRetrievalService.getUserDetailsWithMobile(this.projectName, encodeURI(this._filterMobileNo))
    .subscribe(response => {

      this.messages_1 = [];
      this.messages_2 = [];

      if (response !== null && response.hasOwnProperty('error')) {
        this.messages_2.push({ severity: 'info', summary: 'Error:', detail: response.error });
      } else if (response !== null) {
        const user = response.attributes;
        this.setUserData(user);
        this.enteredFormInput.consent = 'Y';
        this.userUpdate = true;
        this.selfRegistration = (user.source === 'self');
        window.scrollTo(0, 0);
      }

      this.disableSubmit = false;
    },
    error => {
      this.messages_1 = [];
      this.messages_2 = [];
      this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'User Registration retreival error.' });
      this.disableSubmit = false;
    }
  );
  }

  submitRegistration() {
    this.disableSubmit = true;
    this.messages_1 = [];
    this.messages_2 = [];
    this.messages_2.push({ severity: 'info', summary: 'Sending:', detail: 'Submitting registration, please wait...' });

    const category = String(this.enteredFormInput.category);

    const registration = {
      projectName: this.projectName,
      projectVersion: '1.0.0',
      timezone: 'Pacific/Auckland',
      name: this.enteredFormInput.preferredName,
      mobile: this.enteredFormInput.preferredMobileNumber,
      category: this.enteredFormInput.category,
      estimatedDueDate: (category.includes('PregnantMother') ?
                          GeneralUtils.convertDateToISODateString(this.enteredFormInput.estimatedDueDate)
                          : ''),
      childDob: (category.includes('MotherWithBaby') ?
                    GeneralUtils.convertDateToISODateString(this.enteredFormInput.childDob)
                    : ''),
      childGender: (category.includes('MotherWithBaby') ?
                      this.enteredFormInput.childGender
                      : ''),
      childName: (category.includes('MotherWithBaby') ?
                      this.enteredFormInput.childName
                      : ''),
      version: this.enteredFormInput.version,
      ethnicity: this.enteredFormInput.ethnicity,
      consentContacted: this.enteredFormInput.consentContacted,
      consentInfoToHBHF: this.enteredFormInput.consentInfoToHBHF,
      referralSource: this.enteredFormInput.referralSource,
      referrerName: this.enteredFormInput.referrerName,
      referrerContact: this.enteredFormInput.referrerContact,
      referrerDate: GeneralUtils.convertDateToISODateString(this.enteredFormInput.referrerDate),
      re_referral: this.enteredFormInput.re_referral,
      registeredWay: this.enteredFormInput.registeredWay,
      source: this.selfRegistration ? 'self' : 'admin',
      userStatus: this.userUpdate ? 'Update' : 'New'
    };

    this.dataRetrievalService.postUserRegistrationDetails(JSON.stringify(registration, (key, value) => value ? value : undefined))
      .subscribe(response => {

        this.messages_1 = [];
        this.messages_2 = [];

        if (response !== null && response.hasOwnProperty('error')) {
          this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Registration not submitted. ' + response.error });
        } else {
          this.messages_1.push({ severity: 'info', summary: 'Sent:',
            detail: 'Registration submitted. You can now enter a new user registration.' });
          this.clearFormInputs();
          window.scrollTo(0, 0);
        }

        this.disableSubmit = false;

      },

      error => {
        this.messages_1 = [];
        this.messages_2 = [];

        this.messages_2.push({ severity: 'info', summary: 'Error:', detail: 'Registration not submitted. Unknown error.' });

        this.disableSubmit = false;
      }
    );

  }

  numberInputValidator(event: any) {
    GeneralUtils.numberInputValidator(event);
  }

  // check whether the mobile number exists in recipient table
  mobileNotExistValidator(event: any) {
    // if mobile string invalid or waiting for response from API, skip
    if (!event || !event.target || !event.target.value || event.target.value.length < 12 || this.checkingMobile) {
      return;
    }

    const mobile = event.target.value;

    this.checkingMobile = true;
    this.dataRetrievalService.getUserDetailsWithMobile(this.projectName, encodeURI(mobile))
      .subscribe(response => {
          this.checkingMobile = false;

          this.mobileHasRegistered = !
            (response !== null &&
              response.hasOwnProperty('error') &&
              response.error === `Unable to find the user details`);
        },
        error => {
          this.checkingMobile = false;
          // TODO 1 what to do if API call failed
          console.error(error);
        }
      );
  }
}
