import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AdminConfirmRegistrationComponent } from './admin-confirm-registration/admin-confirm-registration.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './authentication.service';
import { AuthGuardService } from './auth-guard.service';
import { TokenInterceptor } from './token-interceptor';
import { DebounceClickDirective } from './debounce.directive';

import { MainViewComponent } from './main-view/main-view.component';
import { MainMenuComponent } from './main-menu/main-menu.component';

import { UserRegistrationDetailsTableComponent } from './shared-tabPanels/user-registration-details-table/user-registration-details-table.component';
import { OutgoingMessageLogTableComponent } from './shared-tabPanels/outgoing-message-log-table/outgoing-message-log-table.component';
import { IncomingMessageLogTableComponent } from './shared-tabPanels/incoming-message-log-table/incoming-message-log-table.component';
import { MobileNumberMappingTableComponent } from './shared-tabPanels/mobile-number-mapping-table/mobile-number-mapping-table.component';
import { AdhocMessageFormComponent } from './shared-tabPanels/adhoc-message-form/adhoc-message-form.component';
import { IntegratedUserDataComponent } from './shared-tabPanels/integrated-user-data/integrated-user-data.component';

import { UserDetailsTableMprComponent } from './Project-mPR/user-details-table-mpr/user-details-table-mpr.component';
import { NewRegistrationPatientComponent } from './Project-mPR/new-registration-patient/new-registration-patient.component';
import { NewRegistrationFamilyComponent } from './Project-mPR/new-registration-family/new-registration-family.component';
import { NewRegistrationStompComponent } from './Project-STOMP/new-registration-stomp/new-registration-stomp.component';
import { UserDetailsTableStompComponent } from './Project-STOMP/user-details-table-stomp/user-details-table-stomp.component';
import { NewRegistrationTextmatchComponent } from './Project-TextMATCH/new-registration-textmatch/new-registration-textmatch.component';
import { UserDetailsTableTextmatchComponent } from './Project-TextMATCH/user-details-table-textmatch/user-details-table-textmatch.component';
import { SwitchProgramTextmatchComponent } from './Project-TextMATCH/switch-program-textmatch/switch-program-textmatch.component';
import { ArchiveUserTextmatchComponent } from './Project-TextMATCH/archive-user-textmatch/archive-user-textmatch.component';
import { NewRegistrationTextmatchLakesMaoriComponent } from './Project-TextMATCH/new-registration-textmatch-lakes-maori/new-registration-textmatch-lakes-maori.component';
import { NewRegistrationTextmatchLakesComponent } from './Project-TextMATCH/new-registration-textmatch-lakes/new-registration-textmatch-lakes.component';
import { FormElementComponent } from './util/form-element/form-element.component';
import { FormElementErrorComponent } from './util/form-element-error/form-element-error.component';
import { DatePipe } from '@angular/common';

import { LoginTextmatchComponent } from './Project-TextMATCH/login-textmatch/login-textmatch.component';
import { RegisterAdminTextmatchComponent } from './Project-TextMATCH/register-admin-textmatch/register-admin-textmatch.component';
import { ForceChangePWDComponent } from './force-change-pwd/force-change-pwd.component';
import { OnlineRegisterTextmatchComponent } from './Project-TextMATCH/online-register-textmatch/online-register-textmatch.component';
import { CandidateUserTableTextmatchComponent } from './Project-TextMATCH/candidate-user-table-textmatch/candidate-user-table-textmatch.component';
import { ResetPwdTextmatchComponent } from './Project-TextMATCH/reset-pwd-textmatch/reset-pwd-textmatch.component';
import { RedirectComponent } from './redirect/redirect.component';


@NgModule({
  declarations: [
    AppComponent,
    UserRegistrationDetailsTableComponent,
    MainViewComponent,
    MainMenuComponent,
    UserDetailsTableMprComponent,
    OutgoingMessageLogTableComponent,
    IncomingMessageLogTableComponent,
    MobileNumberMappingTableComponent,
    AdhocMessageFormComponent,
    NewRegistrationPatientComponent,
    NewRegistrationFamilyComponent,
    LoginComponent,
    NewRegistrationStompComponent,
    UserDetailsTableStompComponent,
    IntegratedUserDataComponent,
    AdminRegistrationComponent,
    AdminConfirmRegistrationComponent,
    DebounceClickDirective,
    NewRegistrationTextmatchComponent,
    UserDetailsTableTextmatchComponent,
    SwitchProgramTextmatchComponent,
    ArchiveUserTextmatchComponent,
    NewRegistrationTextmatchLakesComponent,
    NewRegistrationTextmatchLakesMaoriComponent,
    FormElementComponent,
    FormElementErrorComponent,
    LoginTextmatchComponent,
    RegisterAdminTextmatchComponent,
    ForceChangePWDComponent,
    OnlineRegisterTextmatchComponent,
    CandidateUserTableTextmatchComponent,
    ResetPwdTextmatchComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    MenubarModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    RadioButtonModule,
    MessagesModule,
    MessageModule,
    PasswordModule,
    OverlayPanelModule,
    SelectButtonModule,
    MultiSelectModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    ToastModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationService,
    AuthGuardService,
    MessageService,
    DatePipe,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
