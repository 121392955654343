import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoUsersService} from '../../cognito-users.service';
import {EmailPattern, PasswordPattern} from '../../general-utils';

@Component({
  selector: 'app-login-textmatch',
  templateUrl: './login-textmatch.component.html',
  styleUrls: ['./login-textmatch.component.scss']
})
export class LoginTextmatchComponent implements OnInit {
  submitPending = false;

  emailPattern = EmailPattern;
  passwordPattern = PasswordPattern;

  showErrorMessage = false;

  enteredFormInput: any = {
    username: null,
    password: null
  };

  passwordVisibility = false;

  togglePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
  }

  constructor(protected router: Router, protected cognitoUsersService: CognitoUsersService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.enteredFormInput.username = this.route.snapshot.queryParams.email;
    this.enteredFormInput.password = this.route.snapshot.queryParams.password;

    if (this.enteredFormInput.username && this.enteredFormInput.password) {
      this.login();
    }
  }

  login() {
    if (this.enteredFormInput.username !== null && this.enteredFormInput.password !== null) {
      this.submitPending = true;
      this.showErrorMessage = false;

      this.cognitoUsersService.login(this.enteredFormInput.username, this.enteredFormInput.password).subscribe(
        response => {
          this.submitPending = false;
          if (response === false) {
            this.showErrorMessage = true;
          }
        }
      );

    }
  }
}
