import { Component, OnInit } from '@angular/core';
import { CognitoUsersService } from '../cognito-users.service';
import { EmailPattern } from '../general-utils';

@Component({
  selector: 'app-admin-registration',
  templateUrl: './admin-registration.component.html',
  styleUrls: ['./admin-registration.component.scss']
})
export class AdminRegistrationComponent implements OnInit {
  email: string;
  emailPattern = EmailPattern;
  password: string;
  confirmPassword: string;

  constructor(protected cognitoUsersService: CognitoUsersService) { }

  ngOnInit() {
  }

  register() {
    this.cognitoUsersService.register(this.email, this.password);
  }
}
