import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-user-details-table-stomp',
  templateUrl: './user-details-table-stomp.component.html',
  styleUrls: ['./user-details-table-stomp.component.scss']
})
export class UserDetailsTableStompComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  loading = true;
  maxRows = 20;
  userDetails: any[] = [];
  tableColumns: any[] = [];

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set userDataTab(userDataTab: boolean) {
    this._userDataTab = userDataTab;
    this.refreshTable();
  }

  protected _projectName: string = null;
  protected _userDataTab = false;

  @Output() goToUserDataTabEvent = new EventEmitter<string>();

  goToUserDataTab(mobile: string) {
    this.goToUserDataTabEvent.emit(mobile);
  }

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {
    this.loading = true;
    this.getUserDetails();

    this.tableColumns = [
      { field: 'mobile', header: 'Mobile' },
      { field: 'name', header: 'Name' },
      { field: 'version', header: 'Version' },
    ];
  }

  getUserDetails() {
    this.dataRetrievalService.getUserDetails(this.projectName).subscribe(
      arrayOfDetails => {
        const userDetails = [];
        arrayOfDetails.forEach(
          item => {
            if (item.hasOwnProperty('attributes')) {
              userDetails.push(
                {
                  mobile: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null,
                  name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                  version: (item.attributes.hasOwnProperty('version')) ? item.attributes.version : null,
                }
              );
            }
          }
        );
        this.userDetails = userDetails;
        this.loading = false;
      }
    );
  }
}
