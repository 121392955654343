import {Component, OnInit} from '@angular/core';
import {EmailPattern, GeneralUtils, NZMobileNoPattern} from '../../general-utils';
import {DataRetrievalService} from '../../data-retrieval.service';

@Component({
  selector: 'app-online-register-textmatch',
  templateUrl: './online-register-textmatch.component.html',
  styleUrls: ['./online-register-textmatch.component.scss']
})
export class OnlineRegisterTextmatchComponent implements OnInit {
  projectName = 'TextMATCH_Main';

  disableSubmit = false;
  checkingMobile = false;
  postAPIResult = '';
  showModal = false;

  dropdownOptions: any;
  dropdownOrganisation: any;
  dropdownOptionsEthnicity: any;
  dropdownTextMATCHCulture: any;
  dropdownTextMATCHLanguage: any;
  availableLanguages: any;

  enteredFormInput: any;

  mobilePattern = NZMobileNoPattern;
  mobileHasRegistered: boolean;

  // get from session token
  referralSource: string;
  referrerName: string;
  referrerContact: string;
  emailPattern = EmailPattern;

  today = new Date();

  successMessage = '';

  constructor(protected dataRetrievalService: DataRetrievalService) {}

  ngOnInit() {
    this.getAccountInfo();
    this.initFormInputs();
    this.getRegistrationDropdownOptions();
  }

  getAccountInfo() {
    const payload = GeneralUtils.getCognitoPayload();
    if (payload) {
      this.referralSource = payload['address'] ? payload['address']['formatted'] : null;
      this.referrerName = payload['name'];
      this.referrerContact = payload['email'];
    }
  }

  initFormInputs() {
    this.mobileHasRegistered = undefined;
    this.enteredFormInput = {
      preferredName: null,
      preferredMobileNumber: null,
      confirmMobileNumber: null,
      category: null,
      estimatedDueDate: null,
      childDob: null,
      childGender: null,
      childName: null,
      culture: null,
      language: null,
      ethnicity: null,
      consent: 'Y',
      consentContacted: 'Y',
      consentInfoToHBHF: 'Y',
      referralSource: this.referralSource,
      referrerName: this.referrerName,
      referrerContact: this.referrerContact,
      referrerDate: this.today
    };
  }

  getRegistrationDropdownOptions() {
    this.dataRetrievalService.getRegistrationDropdownOptions().subscribe(
      dropdownOptions => {
        this.dropdownOptions = dropdownOptions;

        this.dropdownOrganisation = [
          { label: 'HealthWest', value: 'HealthWest' },
          { label: 'The Fono', value: 'The Fono' },
          { label: 'CNSST', value: 'CNSST' },
          { label: 'TANI', value: 'TANI'},
          { label: 'NIHI', value: 'NIHI'}
        ];

        this.dropdownOptionsEthnicity = [
          {
            label: 'Asian',
            value: 'Asian',
            items: [
              { label: 'Chinese', value: 'Chinese' },
              { label: 'Korean', value: 'Korean' },
              { label: 'Japanese', value: 'Japanese' },
              { label: 'Other Asian', value: 'Other Asian' }
            ]
          },
          {
            label: 'Māori',
            value: 'Maori',
            items: [
              { label: 'Māori', value: 'Maori' },
            ]
          },
          {
            label: 'Pacific',
            value: 'Pacific',
            items: [
              { label: 'Samoan', value: 'Samoan' },
              { label: 'Tongan', value: 'Tongan' },
              { label: 'Cook Island Māori', value: 'Cook Island Maori' },
              { label: 'Niuean', value: 'Niuean' },
              { label: 'Tuvaluan', value: 'Tuvaluan' },
              { label: 'Tokelauan', value: 'Tokelauan' },
              { label: 'Fijian', value: 'Fijian' },
              { label: 'Other Pacific Islander', value: 'Other Pacific Islander' },
            ]
          },
          {
            label: 'South Asian',
            value: 'South Asian',
            items: [
              { label: 'Indian', value: 'Indian' },
              { label: 'Fijian Indian', value: 'Fijian Indian' },
              { label: 'Bangladeshi', value: 'Bangladeshi' },
              { label: 'Pakistani', value: 'Pakistani' },
              { label: 'Sri Lankan', value: 'Sri Lankan' },
              { label: 'South African Indian', value: 'South African Indian' },
              { label: 'Nepalese', value: 'Nepalese' },
              { label: 'Other South Asian', value: 'Other South Asian' },
            ]
          },
          {
            label: 'Other',
            value: 'Other',
            items: [
              { label: 'European (NZ)', value: 'NZ European' },
              { label: 'Other', value: 'Other' },
            ]
          }
        ];

        this.dropdownTextMATCHCulture = [
          { label: 'Asian', value: 'Asian' },
          { label: 'Māori', value: 'Maori' },
          { label: 'Pacific', value: 'Pacific' },
          { label: 'South Asian', value: 'South Asian' }
        ];

        this.dropdownTextMATCHLanguage = [
          {
            culture: 'Asian',
            languages: [
              { label: 'Chinese', value: 'Chinese' },
              { label: 'Korean', value: 'Korean' },
              { label: 'Japanese', value: 'Japanese' },
              { label: 'English', value: 'English' }
            ]
          },
          {
            culture: 'Maori',
            languages: [
              { label: 'English', value: 'English' },
              { label: 'Te Reo', value: 'Te Reo' },
            ]
          },
          {
            culture: 'Pacific',
            languages: [
              { label: 'English', value: 'English' },
            ]
          },
          {
            culture: 'South Asian',
            languages: [
              { label: 'English', value: 'English' },
            ]
          }
        ];
      }
    );
  }

  showSuccessMessage(): void {
    this.postAPIResult = 'Success';
    this.successMessage = this.mobileHasRegistered ?
      'Thanks for your referral! You\'ll receive an email copy once approved.' : 'Referral successfully submitted!';

    setTimeout(() => {
      this.postAPIResult = '';
    }, 5000);
  }

  submitRegistration(form: any) {

    this.disableSubmit = true;
    this.postAPIResult = '';

    const category = String(this.enteredFormInput.category);

    const registration = {
      recipientCandidate: this.mobileHasRegistered ? 'Y' : 'N',
      projectName: this.projectName,
      projectVersion: '1.0.0',
      timezone: 'Pacific/Auckland',
      name: this.enteredFormInput.preferredName,
      mobile: '+64' + this.enteredFormInput.preferredMobileNumber.replace(/\D/g, ''),
      category: this.enteredFormInput.category,
      estimatedDueDate: (category.includes('PregnantMother') ?
        GeneralUtils.convertDateToISODateString(this.enteredFormInput.estimatedDueDate)
        : ''),
      childDob: (category.includes('MotherWithBaby') ?
        GeneralUtils.convertDateToISODateString(this.enteredFormInput.childDob)
        : ''),
      childGender: (category.includes('MotherWithBaby') ?
        this.enteredFormInput.childGender
        : ''),
      childName: (category.includes('MotherWithBaby') ?
        this.enteredFormInput.childName
        : ''),
      version: this.enteredFormInput.culture + ': ' + this.enteredFormInput.language,
      ethnicity: this.enteredFormInput.ethnicity,
      consentContacted: this.enteredFormInput.consentContacted,
      consentInfoToHBHF: this.enteredFormInput.consentInfoToHBHF,

      referralSource: this.enteredFormInput.referralSource,
      referrerName: this.enteredFormInput.referrerName,
      referrerContact: this.enteredFormInput.referrerContact,

      referrerDate: GeneralUtils.convertDateToISODateString(this.enteredFormInput.referrerDate),
      source: 'admin',
      userStatus: 'New',
      registeredWay: 'OnlineForm'
    };

    this.dataRetrievalService.postUserRegistrationDetails(JSON.stringify(registration, (key, value) => value ? value : undefined))
      .subscribe(response => {
          if (response !== null && response.hasOwnProperty('error')) {
            console.error('API response error: ' + response.error);
            this.postAPIResult = 'Failed';
          } else {
            this.showSuccessMessage();
            // reset the form if submission was successful
            form.resetForm();
            setTimeout(() => {
              this.initFormInputs();
            }, 1000);
            window.scrollTo(0, 0);
          }

          this.disableSubmit = false;
        },

        error => {
          console.error(error);
          this.postAPIResult = 'Failed';
          this.disableSubmit = false;
        }
      );

  }

  numberInputValidator(event: any) {
    const phoneNumber = event.target.value.replace(/\D/g, '');

    // Add space after each part
    const partLengths = [2, 4, 4];
    let formattedNumber = '';
    let index = 0;

    partLengths.forEach(partLength => {
      const part = phoneNumber.substr(index, partLength);
      formattedNumber += part;
      if (part.length === partLength) {
        formattedNumber += ' ';
      }
      index += partLength;
    });

    event.target.value = formattedNumber.trim();
  }

  // check whether the mobile number exists in recipient table
  mobileNotExistValidator(event: any) {
    // if mobile string invalid or waiting for response from API, skip
    if (!event || !event.target || !event.target.value || event.target.value.length < 9 || this.checkingMobile) {
      return;
    }

    const mobile = '64' + event.target.value.replace(/\D/g, '');

    this.checkingMobile = true;
    this.dataRetrievalService.getUserDetailsWithMobile(this.projectName, encodeURI(mobile))
      .subscribe(response => {
          this.checkingMobile = false;

          this.mobileHasRegistered = !
            (response !== null &&
              response.hasOwnProperty('error') &&
              response.error === `Unable to find the user details`);

          if (this.mobileHasRegistered) {
             this.showModal = true;
          }
        },
        error => {
          this.checkingMobile = false;
          // TODO 1 what to do if API call failed
          console.error(error);
        }
      );
  }

  setDefaultLanguage() {
    const cultureLanguage = this.dropdownTextMATCHLanguage.find((c) => c.culture === this.enteredFormInput.culture);
    this.availableLanguages = cultureLanguage ? cultureLanguage.languages : [];

    if (this.availableLanguages.length === 1) {
      this.enteredFormInput.language = this.availableLanguages[0].value;
    } else {
      this.enteredFormInput.language = null;
    }
  }

  onMobilePaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  onModalConfirm(): void {
    this.showModal = false;
  }

  onModalReject(): void {
    this.showModal = false;
    this.initFormInputs();
  }
}
