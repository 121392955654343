import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(protected router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestItem = request;

        const authData = JSON.parse(sessionStorage.getItem('token'));
        const accessToken = JSON.parse(sessionStorage.getItem('accessToken'));

        if (authData && accessToken) {
            requestItem = request.clone(
                { headers: request.headers.set('Authorization', authData.jwtToken).set('accessToken', accessToken.jwtToken) }
            );
        }

        return next.handle(requestItem).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) { }
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            sessionStorage.removeItem('token');
                            this.router.navigate(['/login']);
                        }
                    }
                }
            )
        );
    }
}
