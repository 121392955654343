import { Component, OnInit, ViewChild } from '@angular/core';

import { SelectItem } from 'primeng/api';

import { DataRetrievalService } from '../data-retrieval.service';
import { GeneralUtils } from '../general-utils';
import { UserDetailsTableStompComponent } from '../Project-STOMP/user-details-table-stomp/user-details-table-stomp.component';
import { UserDetailsTableMprComponent } from '../Project-mPR/user-details-table-mpr/user-details-table-mpr.component';
import { TabView } from 'primeng/tabview';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {

  rolePermissions: any;

  projects: SelectItem[] = [
    { label: 'Loading, please wait...', value: null }
  ];

  projectTabIndexTable = [
    {project: 'STOMP', index: 0},
    {project: 'mPR', index: 0},
    {project: 'TextMATCH_Lakes', index: 0},
    {project: 'TextMATCH_Main', index: 7},
  ];

  selectedProject: string = null;
  tabIndex = 0;
  filterMobileNo: string = null;
  userMobileNo: string = null;
  userDetailData: any;

  @ViewChild(UserDetailsTableStompComponent, { static: false }) stomp;
  @ViewChild(UserDetailsTableMprComponent, { static: false }) mpr;

  @ViewChild('tabview', { static: false }) tabview: TabView;

  goToUserDataTab($event) {
    this.filterMobileNo = $event;
    this.tabIndex = 1;
  }

  goToEditUserDataTab($event) {
    this.userMobileNo = $event;
    this.tabIndex = 7;
  }

  // same tab but with all columns data to create new user
  goToNewUserDataTab($event) {
    this.userDetailData = $event;
    this.tabIndex = 7;
  }

  goToArchiveUserTab($event) {
    this.userMobileNo = $event;
    this.tabIndex = 10;
  }

  constructor(protected dataRetrievalService: DataRetrievalService, protected router: Router) { }

  ngOnInit() {
    this.getProjects();
  }

  tabChange($event) {
    if ($event.index === 7 && this.selectedProject === 'TextMATCH_Lakes') {
      alert('Please select a user from user details and click edit.');
      this.tabview.activeIndex = 0;
      this.tabIndex = 0;
    } else {
      this.tabIndex = $event.index;
    }
    this.userMobileNo = null;
    this.userDetailData = null;
  }

  clearRolePermissions() {
    this.rolePermissions = {
      UserDetail_mPR: null,
      UserDetail_STOMP: null,
      UserDetail_TextMATCH: null,
      IncomingMessageLog: null,
      NewUserRegistrationFamily_mPR: null,
      NewUserRegistrationPatient_mPR: null,
      NewUserRegistration_STOMP: null,
      NewUserRegistration_TextMATCH: null,
      Online_Register_TextMATCH: null,  // might delete later, just reuse NewUserRegistration_TextMATCH
      CandidateUser_TextMATCH: null,
      SwitchProgramme_TextMATCH: null,
      OutgoingMessagLog: null,
      PhoneNumberChanged: null,
      RegistrationDetails: null,
      SendAdhocMessage: null,
      UserData: null,
    };
  }

  getProjects() {
    this.dataRetrievalService.getRolePermission(GeneralUtils.getCognitoUsername()).subscribe(
      (rolePermissions) => {
        this.projects = [];

        rolePermissions.forEach(
          (element) => {
            if (element.attributes.hasOwnProperty('projectName')) {
              this.projects.push({ label: element.attributes.projectName, value: element.attributes.projectName });
            }
          }
        );

        this.selectedProject = this.projects.length > 0 ? this.projects[this.projects.length - 1].value : null;

        this.setPermissions(rolePermissions);
      }
    );
  }

  updatePermissions() {
    this.dataRetrievalService.getRolePermission(GeneralUtils.getCognitoUsername()).subscribe(
      rolePermissions => this.setPermissions(rolePermissions)
    );
  }

  setPermissions(rolePermissions) {

    this.projectTabIndexTable.forEach(projectTabIndex => {
      if (projectTabIndex.project === this.selectedProject) {
        this.tabIndex = projectTabIndex.index;
        return;
      }
    });

    const foundPermissions = rolePermissions.find(
      (element) => {
        return (element.attributes.projectName === this.selectedProject);
      }
    );

    if (foundPermissions) {
      this.rolePermissions = foundPermissions.attributes;
      // for nominated admin from refer organizations, redirect to the online register page
      if (this.selectedProject === 'TextMATCH_Main' && this.rolePermissions.Online_Register_TextMATCH) {
        this.router.navigate(['/textmatch/online-register']);
      }
    } else {
      this.clearRolePermissions();
    }
  }
}
