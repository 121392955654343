import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng//api';
import {Router} from '@angular/router';
import {CognitoUsersService} from '../../cognito-users.service';
import {EmailPattern, PasswordPattern} from '../../general-utils';


@Component({
  selector: 'app-reset-pwd-textmatch',
  templateUrl: './reset-pwd-textmatch.component.html',
  styleUrls: ['./reset-pwd-textmatch.component.scss']
})
export class ResetPwdTextmatchComponent implements OnInit {
  step = 0;

  messages: Message[] = [];

  email = '';
  emailPattern = EmailPattern;
  verificationCode = '';

  newPassword = '';
  confirmNewPassword = '';
  passwordPattern = PasswordPattern;

  passwordVisibility = false;
  confirmPasswordVisibility = false;

  togglePasswordVisibility(index: number) {
    if (index === 0) {
      this.passwordVisibility = !this.passwordVisibility;
    } else if (index === 1) {
      this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
    }
  }

  constructor(protected router: Router, protected cognitoUsersService: CognitoUsersService) { }

  ngOnInit() {}

  reset() {
    this.cognitoUsersService.resetPassword(this.email, {
      onSuccess: () => {
        this.step = 1;
      },
      onFailure: err => {
        this.messages = [];
        this.messages.push({ severity: 'warn', summary: 'Reset password error:', detail: err.message });
      }
    });
  }

  confirm() {
    if (this.email && this.verificationCode && this.newPassword) {
      this.cognitoUsersService.confirmPassword(this.email, this.verificationCode, this.newPassword, {
        onSuccess: () => {
          this.step = 3;
        },
        onFailure: err => {
          this.messages = [];
          this.messages.push({ severity: 'warn', summary: 'Change password error:',
            detail: err.message });
          this.step = 0;
        },
      });
    } else {
      this.messages = [];
      this.messages.push({ severity: 'warn', summary: 'Reset password error:',
        detail: 'Please click the reset link in your email. Then input a new password.' });
    }
  }
}
