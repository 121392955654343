import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-integrated-user-data',
  templateUrl: './integrated-user-data.component.html',
  styleUrls: ['./integrated-user-data.component.scss']
})
export class IntegratedUserDataComponent implements OnInit {

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
  }

  @Input()
  set filterMobileNo(filterMobileNo: string) {
    this._filterMobileNo = filterMobileNo;
  }

  @Input()
  set rolePermissions(rolePermissions: any) {
    this._rolePermissions = rolePermissions;
  }

  @ViewChild('op', { static: false }) op: OverlayPanel;

  _projectName: string = null;
  _filterMobileNo: string = null;
  _rolePermissions: any = null;

  constructor() { }

  ngOnInit() { }

}
