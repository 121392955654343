export class Registration {
  projectName: string;
  projectVersion: string;
  timezone: string;
  registerType: string;
  name: string;
  mobile: any;
  confirmMobile: any;
  region: any;
  otherRegion: any;
  suburb: any;
  ethnicity: string[] = [];
  otherEthnicity: any;
  gender: any;
  ageGrp: any;
  category: any;
  estimatedDueDate: any;
  childDob: any;
  childName: any;
  childRelationship: any;
  consent: any;
  hearAbout: any;
  hearAboutOther: any;
  referrer: any;
  childGender: any;
  source: any;
  userStatus: any;
  referrerDate: any;

  version: any;
  consentContacted: any;
  consentInfoToHBHF: any;
  referralSource: any;
  referrerName: any;
  referrerContact: any;

  locale: any;


  constructor() {}
}
