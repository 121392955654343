import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataRetrievalService } from '../../data-retrieval.service';
import { GeneralUtils } from '../../general-utils';

@Component({
  selector: 'app-user-details-table-mpr',
  templateUrl: './user-details-table-mpr.component.html',
  styleUrls: ['./user-details-table-mpr.component.scss']
})
export class UserDetailsTableMprComponent implements OnInit {
  GeneralUtils = GeneralUtils;

  loading = true;
  maxRows = 20;
  userDetails: any[] = [];
  tableColumns: any[] = [];

  @Input()
  set projectName(projectName: string) {
    this._projectName = projectName;
    this.refreshTable();
  }
  get projectName(): string {
    return this._projectName;
  }

  @Input()
  set userDataTab(userDataTab: boolean) {
    this._userDataTab = userDataTab;
    this.refreshTable();
  }

  protected _projectName: string = null;
  protected _userDataTab = false;

  @Output() goToUserDataTabEvent = new EventEmitter<string>();

  goToUserDataTab(mobile: string) {
    this.goToUserDataTabEvent.emit(mobile);
  }

  constructor(protected dataRetrievalService: DataRetrievalService) { }

  ngOnInit() {}

  refreshTable() {

    this.loading = true;
    this.getUserDetails();

    this.tableColumns = [
      { field: 'mobile', header: 'Mobile' },
      { field: 'userType', header: 'Type' },
      { field: 'messageDeliveryTimeFrom', header: 'Preferred delivery time from' },
      { field: 'messageDeliveryTimeTo', header: 'Preferred delivery time to' },
      { field: 'culture', header: 'Culture' },
      { field: 'name', header: 'Name' },
      { field: 'firstSupportName', header: 'Support person name 1' },
      { field: 'secondSupportName', header: 'Support person name 2' },
      { field: 'motivation', header: 'Motivations' },
      { field: 'patientRelationship', header: 'Relationship' },
      { field: 'exercisePrescription', header: 'Exercise' },
      { field: 'secretion', header: 'Secretion' },
      { field: 'smoking', header: 'Smoking' },
    ];

  }

  getUserDetails() {
    this.dataRetrievalService.getUserDetails(this.projectName).subscribe(
      arrayOfDetails => {
        const userDetails = [];

        arrayOfDetails.forEach(
              item => {
                if (item.hasOwnProperty('attributes')) {
                  userDetails.push(
                    {
                      mobile: (item.attributes.hasOwnProperty('mobile')) ? item.attributes.mobile : null,
                      userType: (item.attributes.hasOwnProperty('userType')) ? item.attributes.userType : null,
                      messageDeliveryTimeFrom: (item.attributes.hasOwnProperty('messageDeliveryTimeFrom')) ? item.attributes.messageDeliveryTimeFrom : null,
                      messageDeliveryTimeTo: (item.attributes.hasOwnProperty('messageDeliveryTimeTo')) ? item.attributes.messageDeliveryTimeTo : null,
                      culture: (item.attributes.hasOwnProperty('culture')) ? item.attributes.culture : null,
                      name: (item.attributes.hasOwnProperty('name')) ? item.attributes.name : null,
                      firstSupportName: (item.attributes.hasOwnProperty('firstSupportName')) ? item.attributes.firstSupportName : null,
                      secondSupportName: (item.attributes.hasOwnProperty('secondSupportName')) ? item.attributes.secondSupportName : null,
                      motivation: (item.attributes.hasOwnProperty('motivation')) ? item.attributes.motivation : null,
                      patientRelationship: (item.attributes.hasOwnProperty('patientRelationship')) ? item.attributes.patientRelationship : null,
                      exercisePrescription: (item.attributes.hasOwnProperty('exercisePrescription')) ? item.attributes.exercisePrescription : null,
                      secretion: (item.attributes.hasOwnProperty('secretion')) ? item.attributes.secretion : null,
                      smoking: (item.attributes.hasOwnProperty('smoking')) ? item.attributes.smoking : null,
                    }
                  );
                }
              }
          );

        this.userDetails = userDetails;
        this.loading = false;
      }
    );
  }
}
