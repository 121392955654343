import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-element',
  template: `
    <div class="ui-fluid">
      <div class="ui-g-{{totColumnSize}}" style="display: flex; align-items: center;">
        <h4 class="ui-g-{{lblColSize}}">{{titleVal}}</h4>
        <div class="ui-g-{{valColSize}} {{inputElementVal ? 'ui-inputgroup' : ''}}">
          <span *ngIf="inputElementVal" class="ui-inputgroup-addon"><i class="pi pi-fw pi-pencil"></i></span>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class FormElementComponent implements OnInit {

  titleVal: string;

  inputElementVal = false;

  lblColSize = 2;

  totColumnSize = 12;

  valColSize: any;

  constructor() { }

  ngOnInit() {
    this.valColSize = this.totColumnSize - this.lblColSize;
  }

  @Input()
  set title(title: string) {
    this.titleVal = title;
  }

  @Input()
  set inputElement(inputElementVal: boolean) {
    this.inputElementVal = inputElementVal;
  }

    @Input()
  set totalColumnSize(totalColumnSize: any) {
    this.totColumnSize = totalColumnSize;
  }

  @Input()
  set labelColumnSize(labelColumnSize: any) {
    this.lblColSize = labelColumnSize;
  }

}
